import { object, string } from 'yup';
import { Formik, FormikHelpers } from 'formik';

import { Json } from '../models/json';
import { Static } from '../../../../../static';
import { Navigation, VehicleStatus } from '../models/navigation.enum';
import { toast } from 'react-toastify';
import agent from '../../../../../api/agent';
import { JsonResponse, VehicleUsedByThirdPart } from '../models/jsonResponse';
import { DocumentData } from '../models/document-data';
import UsedByThirdPartForm from './UsedByThirdPartForm';
import { useStore } from '../../../../../context/store';

type Props = {
	json: Json;
	documentId: number;
	selectedFrame: string;
	changeView: (n: number) => void;
};

const validationSchema = object().shape(
	{
		address: string().required("L'indirizzo è obbligatorio"),
		cap: string().required('Il CAP è obbligatorio'),
		city: string().required('La città è obbligatoria'),
		state: string().required('Lo stato è obbligatorio'),
		name: string().when('businessName', {
			is: (businessName: string) => !businessName?.trim(),
			then: (name) =>
				name.required('Il Nome o la ragione sociale sono obbligatori'),
			otherwise: (name) => name.nullable(),
		}),
		surname: string().when('businessName', {
			is: (businessName: string) => !businessName?.trim(),
			then: (surname) =>
				surname.required('Il cognome o la ragione sociale sono obbligatori'),
			otherwise: (surname) => surname.nullable(),
		}),
		businessName: string().when(['name', 'surname'], {
			is: (name: string, surname: string) => !name?.trim() || !surname?.trim(),
			then: (bname) =>
				bname.required(
					'La ragione sociale o il nome e cognome sono obbligatori'
				),
			otherwise: (bname) => bname.nullable(),
		}),
	},
	[
		['name', 'businessName'],
		['surname', 'businessName'],
	]
);

const UsedByThirdPart = (props: Props) => {
	const { loaderStore } = useStore();
	const initialValues = {
		name: '',
		surname: '',
		address: '',
		cap: '',
		city: '',
		state: '',
		businessName: '',
		phoneNumber: '',
	};

	const submit = async (values: any, actions: FormikHelpers<any>) => {
		let documentData = mapJsonObject(values);
		loaderStore.setAppLoadingTrue();
		agent.Document.saveDocumentData(documentData)
			.then((res) => {
				if (res === true) {
					toast.success(Static.thanks);
					props.changeView(Navigation.mainView);
				} else toast.error(Static.saveFailed);

				actions.setSubmitting(false);
				loaderStore.setAppLoadingFalse();
			})
			.catch((ex) => {
				actions.setSubmitting(false);
				loaderStore.setAppLoadingFalse();
				toast.error(Static.anUnexpectedErrorHasOccurred);
			});
	};
	const mapJsonObject = (formData: any) => {
		let jsonUsedByThirdPartResponse = new JsonResponse();
		jsonUsedByThirdPartResponse.CampaignDate = props.json.CampaignDate;
		jsonUsedByThirdPartResponse.HolderName = props.json.HolderName;
		jsonUsedByThirdPartResponse.HolderSurname = props.json.HolderSurname;
		jsonUsedByThirdPartResponse.HolderBusinessName =
			props.json.HolderBusinessName;
		jsonUsedByThirdPartResponse.HolderVat = props.json.HolderVAT;
		jsonUsedByThirdPartResponse.HolderCF = props.json.HolderCF;
		jsonUsedByThirdPartResponse.IvecoContactEmail =
			props.json.IvecoContactEmail;
		jsonUsedByThirdPartResponse.IvecoContactPhoneNumber =
			props.json.IvecoContactNumber;
		jsonUsedByThirdPartResponse.Language = props.json.LanguageFlag;
		jsonUsedByThirdPartResponse.FrameNumber = props.selectedFrame;
		jsonUsedByThirdPartResponse.CampaignId = props.json.CampaignId;
		jsonUsedByThirdPartResponse.VehicleStatus =
			VehicleStatus[VehicleStatus.UsedByThirdPart];
		jsonUsedByThirdPartResponse.VehicleStatusId =
			VehicleStatus.UsedByThirdPart.toString();
		jsonUsedByThirdPartResponse.CampaignDescription =
			props.json.CampaignDescription;
		jsonUsedByThirdPartResponse.HolderAddress = props.json.HolderAddress;
		jsonUsedByThirdPartResponse.HolderCap = props.json.HolderCap;
		jsonUsedByThirdPartResponse.HolderCity = props.json.HolderCity;
		jsonUsedByThirdPartResponse.HolderState = props.json.HolderState;
		jsonUsedByThirdPartResponse.HolderEmail = props.json.HolderEmail;
		jsonUsedByThirdPartResponse.HolderPec = props.json.HolderPec;
		jsonUsedByThirdPartResponse.HolderWebSite = props.json.HolderWebSite;
		jsonUsedByThirdPartResponse.HolderPhoneNumber =
			props.json.HolderPhoneNumber;

		let jsonUsedByThirdPart = new VehicleUsedByThirdPart();
		jsonUsedByThirdPart.UtilizerName = formData.name;
		jsonUsedByThirdPart.UtilizerSurname = formData.surname;
		jsonUsedByThirdPart.UtilizerAddress = formData.address;
		jsonUsedByThirdPart.UtilizerCAP = formData.cap;
		jsonUsedByThirdPart.UtilizerCity = formData.city;
		jsonUsedByThirdPart.UtilizerState = formData.state;
		jsonUsedByThirdPart.UtilizerBusinessName = formData.businessName;
		jsonUsedByThirdPart.UtilizerPhoneNumber = formData.phoneNumber;

		jsonUsedByThirdPartResponse.VehicleUsedByThirdPartDetails =
			jsonUsedByThirdPart;

		let documentData = new DocumentData();
		documentData.documentId = props.documentId;
		documentData.json = JSON.stringify(jsonUsedByThirdPartResponse);

		return documentData;
	};

	const goBack = () => {
		props.changeView(Navigation.mainView);
	};

	return (
		<div id='usedByThirdPartForm' className='formGroup mt-5'>
			<div className='row g-0 menuTitle'>Veicolo in uso a:</div>
			<Formik
				validateOnMount
				enableReinitialize
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={submit}
			>
				<UsedByThirdPartForm goBack={goBack} />
			</Formik>
		</div>
	);
};

export default UsedByThirdPart;
