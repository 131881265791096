import { useStore } from '../../../../../../context/store';
import { Static } from '../../../../../../static';
import agent from '../../../../../../api/agent';

type Props = {};

const Video = (props: Props) => {
	const { modalStore } = useStore();

	const closeModal = () => {
		modalStore.closeModal();
		agent.Tracking.track(Static.closeModalVideo + ' ' + Static.fromModalVideo);
	};

	return (
		<div id='video'>
			<div className='pb-0 modal-header text-center'>
				<h3 className='modal-title text-center'>Buongiorno Mario</h3>
			</div>

			<div className='modal-body pb-0'>
				<table className='table table-sm table-borderless mb-0 grey app-semibold'>
					<tbody>
						<div className='m-3'>
							<div className='card app-card-youTube'>
								<iframe
									style={{ border: 0 }}
									height='300'
									src='https://www.youtube.com/embed/IlmBcjC4Ssw?autoplay=1'
									title='YouTube video player'
									allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
									allowFullScreen
								></iframe>
							</div>
						</div>
					</tbody>
				</table>
			</div>

			<div className='modal-footer pt-0'>
				<button
					onClick={closeModal}
					className='app-pointer btn app-height-navicon'
				>
					<i className='fa fa-arrow-left green'></i>
				</button>
			</div>
		</div>
	);
};

export default Video;
