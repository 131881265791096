export enum Navigation {
  mainView = 1,
  soldView,
  usedByThirdPartView,
  stolenView,
  demolishedView,
  restoredView,
}

export enum VehicleStatus {
  Sold,
  UsedByThirdPart,
  Stolen,
  Demolished,
  Restored,
  RegistryUpdate,
}
