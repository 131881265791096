import { Navigate } from 'react-router-dom';
import { useStore } from '../context/store';

const PrivateRoute = ({ children }: { children: JSX.Element }) => {
	const { userStore } = useStore();
	// If authorized, return an outlet that will render child elements
	// If not, return element that will navigate to login page
	return userStore.loggedIn() ? children : <Navigate to='/not-found' />;
};

export default PrivateRoute;
