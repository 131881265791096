import { observer } from 'mobx-react-lite';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

import ModalContainer from '../components/modal/ModalContainer';
import { useStore } from '../context/store';
import { ToastContainer } from 'react-toastify';

function App({ children }: { children: React.ReactNode }) {
	const { loaderStore } = useStore();

	return (
		<>
			<ToastContainer position='bottom-right' />
			{loaderStore.appLoading && (
				<div
					className='loader'
					style={{
						position: 'fixed',
						width: ' 100%',
						height: '100%',
						zIndex: '1000',
						top: '50%',
						left: '0px',
					}}
					id='loading'
				>
					<div className='d-flex justify-content-center'>
						<div className='spinner-border' role='status'></div>
					</div>
				</div>
			)}
			<div className={loaderStore.appLoading ? 'overlay' : ''}>
				{children}
				<ModalContainer />
			</div>
		</>
	);
}

//loader per il json
export default observer(App);
