import { object, string } from 'yup';
import { Formik, FormikHelpers } from 'formik';

import { Json } from '../models/json';
import agent from '../../../../../api/agent';
import { toast } from 'react-toastify';
import { Navigation, VehicleStatus } from '../models/navigation.enum';
import { Static } from '../../../../../static';
import { JsonResponse, VehicleSold } from '../models/jsonResponse';
import { DocumentData } from '../models/document-data';
import SoldForm from './SoldForm';
import { useStore } from '../../../../../context/store';

type Props = {
	json: Json;
	documentId: number;
	selectedFrame: string;
	changeView: (event: number) => void;
};

const validationSchema = object().shape(
	{
		address: string().required("L'indirizzo è obbligatorio"),
		cap: string().required('Il CAP è obbligatorio'),
		city: string().required('La città è obbligatoria'),
		state: string().required('Lo stato è obbligatorio'),
		name: string().when('businessName', {
			is: (businessName: string) => !businessName?.trim(),
			then: (name) =>
				name.required('Il Nome o la ragione sociale sono obbligatori'),
			otherwise: (name) => name.nullable(),
		}),
		surname: string().when('businessName', {
			is: (businessName: string) => !businessName?.trim(),
			then: (surname) =>
				surname.required('Il cognome o la ragione sociale sono obbligatori'),
			otherwise: (surname) => surname.nullable(),
		}),
		businessName: string().when(['name', 'surname'], {
			is: (name: string, surname: string) => !name?.trim() || !surname?.trim(),
			then: (bname) =>
				bname.required(
					'La ragione sociale o il nome e cognome sono obbligatori'
				),
			otherwise: (bname) => bname.nullable(),
		}),
	},
	[
		['name', 'businessName'],
		['surname', 'businessName'],
	]
);

const Sold = (props: Props) => {
	const { loaderStore } = useStore();
	const initialValues = {
		name: '',
		surname: '',
		address: '',
		cap: '',
		city: '',
		state: '',
		businessName: '',
		phoneNumber: '',
	};

	const submit = async (values: any, actions: FormikHelpers<any>) => {
		let documentData = mapJsonObject(values);
		loaderStore.setAppLoadingTrue();
		agent.Document.saveDocumentData(documentData)
			.then((res) => {
				if (res === true) {
					toast.success(Static.thanks);
					props.changeView(Navigation.mainView);
				} else toast.error(Static.saveFailed);

				actions.setSubmitting(false);
				loaderStore.setAppLoadingFalse();
			})
			.catch((ex) => {
				actions.setSubmitting(false);
				loaderStore.setAppLoadingFalse();
				toast.error(Static.anUnexpectedErrorHasOccurred);
			});
	};

	const mapJsonObject = (formData: any) => {
		let jsonSoldResponse = new JsonResponse();
		jsonSoldResponse.CampaignDate = props.json.CampaignDate;
		jsonSoldResponse.HolderName = props.json.HolderName;
		jsonSoldResponse.HolderSurname = props.json.HolderSurname;
		jsonSoldResponse.HolderBusinessName = props.json.HolderBusinessName;
		jsonSoldResponse.HolderVat = props.json.HolderVAT;
		jsonSoldResponse.HolderCF = props.json.HolderCF;
		jsonSoldResponse.IvecoContactEmail = props.json.IvecoContactEmail;
		jsonSoldResponse.IvecoContactPhoneNumber = props.json.IvecoContactNumber;
		jsonSoldResponse.Language = props.json.LanguageFlag;
		jsonSoldResponse.FrameNumber = props.selectedFrame;
		jsonSoldResponse.CampaignId = props.json.CampaignId;
		jsonSoldResponse.VehicleStatus = VehicleStatus[VehicleStatus.Sold];
		jsonSoldResponse.VehicleStatusId = VehicleStatus.Sold.toString();
		jsonSoldResponse.CampaignDescription = props.json.CampaignDescription;
		jsonSoldResponse.HolderAddress = props.json.HolderAddress;
		jsonSoldResponse.HolderCap = props.json.HolderCap;
		jsonSoldResponse.HolderCity = props.json.HolderCity;
		jsonSoldResponse.HolderState = props.json.HolderState;
		jsonSoldResponse.HolderEmail = props.json.HolderEmail;
		jsonSoldResponse.HolderPec = props.json.HolderPec;
		jsonSoldResponse.HolderWebSite = props.json.HolderWebSite;
		jsonSoldResponse.HolderPhoneNumber = props.json.HolderPhoneNumber;
		let jsonSold = new VehicleSold();
		jsonSold.NewHolderName = formData.name;
		jsonSold.NewHolderSurname = formData.surname;
		jsonSold.NewHolderAddress = formData.address;
		jsonSold.NewHolderCAP = formData.cap;
		jsonSold.NewHolderCity = formData.city;
		jsonSold.NewHolderState = formData.state;
		jsonSold.NewHolderBusinessName = formData.businessName;
		jsonSold.NewHolderPhoneNumber = formData.phoneNumber;

		jsonSoldResponse.VheicleSoldDetails = jsonSold;

		let documentData = new DocumentData();
		documentData.documentId = props.documentId;
		documentData.json = JSON.stringify(jsonSoldResponse);

		return documentData;
	};

	const goBack = () => {
		props.changeView(Navigation.mainView);
	};

	return (
		<div id='soldForm' className='formGroup'>
			<div className='row g-0 menuText'>VENDUTO A:</div>
			<Formik
				validateOnMount
				enableReinitialize
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={submit}
			>
				<SoldForm goBack={goBack} />
			</Formik>
		</div>
	);
};

export default Sold;
