import { useFormikContext } from 'formik';

import MyTextInput from '../../../../../components/MyTextInput';

type Props = {
	goBack: () => void;
};

const SoldComponentForm = (props: Props) => {
	const { handleSubmit, isSubmitting, isValid, dirty } =
		useFormikContext<any>();

	console.log(
		'valid : ' + isValid + ', dirty: ' + dirty + ', submitting: ',
		isSubmitting
	);
	return (
		<form onSubmit={handleSubmit}>
			<div className='input-group mb-3'>
				<MyTextInput
					type='text'
					className='form-control'
					placeholder='Nome'
					name='name'
				/>
			</div>
			<div className='input-group mb-3'>
				<MyTextInput
					type='text'
					className='form-control'
					placeholder='Cognome'
					name='surname'
				/>
			</div>
			<div className='input-group mb-3'>
				<MyTextInput
					type='text'
					className='form-control'
					placeholder='Indirizzo'
					name='address'
				/>
			</div>
			<div className='input-group mb-3'>
				<MyTextInput
					type='text'
					className='form-control'
					placeholder='CAP'
					name='cap'
				/>
			</div>
			<div className='input-group mb-3'>
				<MyTextInput
					type='text'
					className='form-control'
					placeholder='Città'
					name='city'
				/>
			</div>
			<div className='input-group mb-3'>
				<MyTextInput
					type='text'
					className='form-control'
					placeholder='Nazione'
					name='state'
				/>
			</div>
			<div className='input-group mb-3'>
				<MyTextInput
					type='text'
					className='form-control'
					placeholder='RagioneSociale'
					name='businessName'
				/>
			</div>
			<div className='input-group mb-3'>
				<MyTextInput
					type='text'
					className='form-control'
					placeholder='Telefono'
					name='phoneNumber'
				/>
			</div>
			<div className='input-group mb-3'>
				<div className='input-group-prepend ms-auto me-auto'>
					<button
						className='btn btn-outline-secondary btnConfirm'
						type='submit'
						disabled={isSubmitting || !isValid || !dirty}
					>
						Conferma
					</button>
				</div>
			</div>
			<div className='input-group mb-3'>
				<div className='input-group-prepend ms-auto me-auto'>
					<button
						onClick={props.goBack}
						className='btn btn-outline-secondary btnCancel'
						type='submit'
					>
						Annulla
					</button>
				</div>
			</div>
		</form>
	);
};

export default SoldComponentForm;
