import React, { useState } from 'react';
import { Static } from '../../../../../static';
import { EtichettaValore } from '../../../../../models/etichetta-valore';

type Props = {
	sportello: EtichettaValore;
};

const ReferenceDesk = (props: Props) => {
	const [showDetails, setShowDetails] = useState(false);

	const onSOLClick = async () => {
		window.open(Static.urlDemoCustomerArea, '_blank');
	};

	const viewOnGoogleMaps = async () => {
		window.open(Static.urlGoogleMaps + props.sportello.Valore, '_blank');
	};

	const toggleDetails = async () => {
		setShowDetails((prev) => !prev);
	};
	return (
		<>
			<div className='card app-card-with-background ps-5 pe-5 pt-3 pb-3 mb-3 d-block d-sm-none'>
				<div className='app-toggle-show' onClick={toggleDetails}>
					<h4 className='mb-0'>SPORTELLO PIÙ VICINO</h4>
					<i
						className={showDetails ? 'fa fa-angle-up' : 'fa fa-angle-down'}
						style={{ fontSize: '1.5rem' }}
					></i>
				</div>
			</div>
			{showDetails && (
				<div className='card app-card-with-background p-5 d-block d-sm-none'>
					<div className='app-info-div'>
						<img
							alt=''
							className='app-info-icon'
							src='/images/demo/invoice//MapsIcon.svg'
						/>
						<div className='ms-2'>
							<span> {props.sportello.Etichetta} </span>
							<br />
							<span className='app-bold'>{props.sportello.Valore}</span>
						</div>
					</div>
					<div className='app-info-div mt-4'>
						<img
							alt=''
							className='app-info-icon'
							src='/images/demo/invoice//WorldIcon.svg'
						/>
						<div className='ms-2'>
							<span>SI ACCEDE ALLO SPORTELLO SOLO SU PRENOTAZIONE.</span>
							<br />
							<span>
								Puoi prenotare l’appuntamento sullo
								<span className='app-link' onClick={onSOLClick}>
									{' '}
									Sportello Online PRT
								</span>
							</span>
						</div>
					</div>
					<button
						className='btn app-btn-details mt-4'
						onClick={viewOnGoogleMaps}
					>
						<img
							alt=''
							className='app-icon-white-hover'
							src='/images/demo/invoice//OnGoogleMapsIcon.svg'
						/>
						Visualizza su Google Maps
					</button>
				</div>
			)}

			<div className='card app-card-with-background p-5 d-none d-sm-block'>
				<h4>SPORTELLO PIÙ VICINO</h4>
				<div className='app-info-div mt-4'>
					<img
						alt=''
						className='app-info-icon'
						src='/images/demo/invoice//MapsIcon.svg'
					/>
					<div className='ms-2'>
						<span> {props.sportello.Etichetta} </span>
						<br />
						<span className='app-bold'>{props.sportello.Valore}</span>
					</div>
				</div>
				<div className='app-info-div mt-4'>
					<img
						alt=''
						className='app-info-icon'
						src='/images/demo/invoice//WorldIcon.svg'
					/>
					<div className='ms-2'>
						<span>SI ACCEDE ALLO SPORTELLO SOLO SU PRENOTAZIONE.</span>
						<br />
						<span>
							Puoi prenotare l’appuntamento sullo{' '}
							<span className='app-link' onClick={onSOLClick}>
								Sportello Online PRT
							</span>
						</span>
					</div>
				</div>
				<button className='btn app-btn-details mt-4' onClick={viewOnGoogleMaps}>
					<img
						alt=''
						className='app-icon-white-hover'
						src='/images/demo/invoice//OnGoogleMapsIcon.svg'
					/>
					Visualizza su Google Maps
				</button>
			</div>
		</>
	);
};

export default ReferenceDesk;
