import { Navigate, useLoaderData } from 'react-router-dom';

import { Json } from '../models/json';
import DataForm from './DataForm';

const Main = () => {
	const json = useLoaderData() as Json;
	if (!json) return <Navigate to='/not-found' />;
	return (
		<div id='iveco-vehicle-main'>
			<div className='d-none d-sm-block'>
				<div className='main-container'>
					<div className='row divImageHeader' id='imgHeader'>
						<img
							alt=''
							className='imgTop'
							src='/images/iveco/recall-campaign/ivecoBgTop.jpg'
						/>
					</div>
					<div className='central-body' id='body'>
						<div className='row topBar'>
							<div className='col-md-3 formData'>
								<DataForm json={json} />
							</div>
							<div className='col-md-3 divLogoCHI'>
								<img
									alt=''
									className='logoCHI'
									src='/images/iveco/recall-campaign/CNHI_LOGO.png'
								/>
							</div>
						</div>
						<div>
							<div className='campaignInfoContainer'>
								<div className='row'>
									Gentile Cliente, per garantirle la massima tempestività nella
									comunicazione di eventuali campagne di richiamo dei veicoli
									che ci risultano in Suo possesso, Le chiediamo di aggiornare
									l'anagrafica della Sua azienda e comunicarci eventuali
									variazioni di proprietà dei veicoli
								</div>
								<div className='row campaignTitle'>
									CAMPAGNA DI RICHIAMO {json.CampaignId}
								</div>
								<div className='row campaignDescription'>
									{json.CampaignDescription}
								</div>
							</div>
						</div>
						<div className='row bottomBar'>
							<img
								alt=''
								className='logoBottom'
								src='/images/iveco/recall-campaign/IVECO_BLU_LOGO.png'
							/>
						</div>
					</div>
					<div className='row divFooter' id='footer'>
						<div className='center'>
							Copyright &copy; All Rights Reserved
							<a
								target='_blank'
								href='/images/iveco/recall-campaign/GDPR_AMS_RecallCampaign_IT_01_IT.pdf'
							>
								{' '}
								Condizioni trattamento dati - Documento Privacy
							</a>
						</div>
					</div>
				</div>
			</div>

			<div className='d-block d-sm-none'>
				<div className='main-container-425'>
					<div className='row divImageHeader' id='imgHeader'>
						<img
							alt=''
							className='imgTop'
							src='/images/iveco/recall-campaign/ivecoBgTop.jpg'
						/>
					</div>
					<div className='row topBar'>
						<div className='col-md-3 divLogoCHI'>
							<img
								alt=''
								className='logoCHI'
								src='/images/iveco/recall-campaign/CNHI_LOGO.png'
							/>
						</div>
					</div>
					<div>
						<div className='col-xs-12 campaignInfoContainer m-auto'>
							<div className='campaignTitle'>
								CAMPAGNA DI RICHIAMO {json.CampaignId}
							</div>
							<div className='row campaignDescription'>
								{json.CampaignDescription}
							</div>
							<div className='row mt-3'>
								Gentile Cliente, per garantirle la massima tempestività nella
								comunicazione di eventuali campagne di richiamo dei veicoli che
								ci risultano in Suo possesso, Le chiediamo di aggiornare
								l'anagrafica della Sua azienda e comunicarci eventuali
								variazioni di proprietà dei veicoli compilando la sezione
								dedicata.
							</div>
						</div>
					</div>
					<div className='row' id='body'>
						<div className='col-md-3 formData-mobile'>
							<DataForm json={json} />
						</div>
					</div>
					<div className='row'>
						<img
							alt=''
							className='logoBottom-mobile'
							src='/images/iveco/recall-campaign/IVECO_BLU_LOGO.png'
						/>
					</div>
					<div className='row divFooter-mobile' id='footer'>
						<div className='center'>
							Copyright &copy; All Rights Reserved <br />
							<a
								target='_blank'
								href='/images/iveco/recall-campaign/GDPR_AMS_RecallCampaign_IT_01_IT.pdf'
							>
								Condizioni trattamento dati - Documento Privacy
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Main;
