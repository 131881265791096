import { useState } from 'react';

import agent from '../../../../../api/agent';
import { Static } from '../../../../../static';
import { Svuotamenti } from '../models/jsonVeritas';

type Props = {
	svuotamento: Svuotamenti;
};

const Emptyings = (props: Props) => {
	const [showDetails, setShowDetails] = useState(false);

	const toggleDetails = async () => {
		agent.Tracking.track(
			`${Static.toggleDetail} ${Static.fromEmptyingsComponent}`
		);
		setShowDetails((prev) => !prev);
	};

	return (
		<>
			<div className='mb-2 d-none d-sm-block'>
				{props.svuotamento.NumeroContenitore && (
					<div className='app-bold'>
						{props.svuotamento.NumeroContenitore.Etichetta}
						<span>{props.svuotamento.NumeroContenitore.Valore}</span>
					</div>
				)}
				{props.svuotamento.NumeroChiavetta && (
					<div className='app-bold'>
						{props.svuotamento.NumeroChiavetta.Etichetta}
						{props.svuotamento.NumeroChiavetta.Valore}
					</div>
				)}
				<div>
					{props.svuotamento.Tipologia.Etichetta}
					<span className='app-bold'>{props.svuotamento.Tipologia.Valore}</span>
				</div>
				<div>
					{props.svuotamento.Volume.Etichetta}
					<span className='app-bold'>{props.svuotamento.Volume.Valore}</span>
				</div>
				<i style={{ cursor: 'pointer' }} onClick={toggleDetails}>
					{showDetails ? 'Nascondi' : 'Vedi'} date di rilevazione{' '}
					{!showDetails ? (
						<i className='fa-solid fa-caret-down'></i>
					) : (
						<i className='fa-solid fa-caret-up'></i>
					)}
				</i>

				{showDetails &&
					props.svuotamento.DateSvuotamenti.map((data, index) => (
						<div key={index}>{data}</div>
					))}
			</div>

			<div className='mt-2 d-block d-sm-none'>
				{props.svuotamento.NumeroContenitore && (
					<div className='app-info-detail'>
						<span className='me-2 text-uppercase'>
							{props.svuotamento.NumeroContenitore.Etichetta}
						</span>
						<span className='app-only-bold text-end'>
							{props.svuotamento.NumeroContenitore.Valore}
						</span>
					</div>
				)}
				{props.svuotamento.NumeroChiavetta && (
					<div className='app-info-detail'>
						<span className='me-2 text-uppercase'>
							{props.svuotamento.NumeroChiavetta.Etichetta}
						</span>
						<span className='app-only-bold text-end'>
							{props.svuotamento.NumeroChiavetta.Valore}
						</span>
					</div>
				)}
				<div className='app-info-detail'>
					<span className='me-2 text-uppercase'>
						{props.svuotamento.Tipologia.Etichetta}
					</span>
					<span className='app-only-bold text-end'>
						{props.svuotamento.Tipologia.Valore}
					</span>
				</div>
				<div className='app-info-detail'>
					<span className='me-2 text-uppercase'>
						{props.svuotamento.Volume.Etichetta}
					</span>
					<span className='app-only-bold text-end'>
						{props.svuotamento.Volume.Valore}
					</span>
				</div>
			</div>
		</>
	);
};

export default Emptyings;
