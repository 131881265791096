import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.css';
import { RouterProvider } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.min.css';

import './index.css';
import App from './layout/App';
import { StoreContext, store } from './context/store';
import router from './layout/router';

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);

root.render(
	<StoreContext.Provider value={store}>
		<App>
			<RouterProvider router={router} />
		</App>
	</StoreContext.Provider>
);
