import {
	Navigate,
	useLocation,
	useNavigate,
	useParams,
	useRouteLoaderData,
} from 'react-router';
import { toast } from 'react-toastify';
import { saveAs } from 'file-saver';

import { Json } from '../models/json';
import agent from '../../../../../api/agent';
import { Static } from '../../../../../static';

const Header = () => {
	const json = useRouteLoaderData('demoJsonLoader') as Json;

	const location = useLocation();
	const navigate = useNavigate();
	const { documentId } = useParams();

	const checkMainPage = () => {
		return (
			location.pathname === '/invoice/demo/luce/document/' + documentId ||
			location.pathname === '/demo/invoice/luce/document/' + documentId
		);
	};

	const isMain = checkMainPage();

	if (!json) return <Navigate to='/not-found' />;

	const checkClientDetailPage = () => {
		return (
			location.pathname ===
				'/invoice/demo/luce/document/' + documentId + '/client' ||
			location.pathname ===
				'/demo/invoice/luce/document/' + documentId + '/client'
		);
	};

	const toggleClientDetail = () => {
		if (!checkClientDetailPage()) goToClientDetail();
		else goToMain();
	};

	const goToClientDetail = () => {
		window.scroll(0, 0);

		agent.Tracking.track(
			Static.toggleClientDetail + ' ' + Static.fromHeaderComponent,
			'client-detail'
		);

		navigate('/demo/invoice/luce/document/' + documentId + '/client');
	};

	const goToMain = () => {
		window.scroll(0, 0);

		agent.Tracking.track(
			Static.toggleCancel + ' ' + Static.fromHeaderComponent,
			'document'
		);

		navigate('/demo/invoice/luce/document/' + documentId);
	};

	const goToClientConsumptionDetail = () => {
		window.scroll(0, 0);

		agent.Tracking.track(
			Static.toggleClientConsumptionDetail + ' ' + Static.fromHeaderComponent,
			'client-consumption-detail'
		);

		navigate('/demo/invoice/luce/document/' + documentId + '/consumption');
	};

	const onDownloadInvoiceClick = () => {
		agent.Tracking.track(
			Static.onDownloadInvoiceClick + ' ' + Static.fromHeaderComponent,
			'Download Bolletta'
		);
		agent.Document.getAttachment(
			Number(documentId),
			json.AllegatoNomeFattura!
		).then(
			(attachment) => {
				var filename = json.AllegatoNomeFattura;
				saveAs(attachment, filename);
			},
			() => {
				toast.error(Static.anUnexpectedErrorHasOccurred);
			}
		);
	};

	return (
		<div id='header' className='row border-bottom sticky-top pb-4 pt-4'>
			<div className='col-3 align-self-center'>
				<img
					alt=''
					className='app-max-height ms-3'
					src='/images/demo/invoice/LogoPRT.png'
				/>
				<img
					alt=''
					className='app-max-height-luce ms-md-3 ms-2'
					src='/images/demo/invoice/LogoLuce.svg'
				/>
			</div>

			<div className='col-7 align-self-end'>
				<div onClick={toggleClientDetail} style={{ cursor: 'pointer' }}>
					<h3>
						Contratto <span className='green'>{json.NumeroContratto}</span>{' '}
						Cliente <span className='green'>{json.NumeroCliente}</span>
					</h3>
				</div>
			</div>

			<div className='col-2 align-self-center app-no-padding text-center'>
				{isMain && (
					<div className='dropdown'>
						<button
							className='btn app-pointer app-height-navicon'
							type='button'
							data-bs-toggle='dropdown'
							aria-expanded='false'
						>
							<i className='fa fa-navicon green'></i>
						</button>
						<ul className='dropdown-menu app-dropdown-menu dropdown-menu-center mt-4'>
							<li
								className='dropdown-item white app-pointer'
								onClick={onDownloadInvoiceClick}
							>
								Scarica la bolletta
							</li>
							<li
								className='dropdown-item white app-pointer'
								onClick={goToClientDetail}
							>
								Contratto cliente
							</li>
							<li
								className='dropdown-item white app-pointer'
								onClick={goToClientConsumptionDetail}
							>
								Riepilogo consumi
							</li>
						</ul>
					</div>
				)}
				{!isMain && (
					<div>
						<button
							onClick={goToMain}
							type='button'
							className='app-pointer btn app-height-navicon'
						>
							<i className='fa fa-arrow-left green'></i>
						</button>
					</div>
				)}
			</div>
		</div>
	);
};

export default Header;
