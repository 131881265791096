import React, { useState } from 'react';
import { EtichettaValore } from '../../../../../models/etichetta-valore';

type Props = {
	importoTotaleContratto: EtichettaValore;
	dettaglioImporti: EtichettaValore[];
};

const AmountsSummary = (props: Props) => {
	const [collapsed, setCollapsed] = useState(true);

	const toggleCollapse = async () => {
		setCollapsed((prev) => !prev);
	};

	return (
		<div
			id='amounts-summary'
			className='card app-card-with-dark-background p-5'
		>
			<div className='app-riep-header'>
				<h4>RIEPILOGO IMPORTI</h4>
				<div className='app-riep-header-info'>
					<h2>{props.importoTotaleContratto.Valore}€</h2>
					<p className='app-p-bigger text-end'>
						{props.importoTotaleContratto.Etichetta}
					</p>
				</div>
			</div>

			<div className='d-none d-sm-block'>
				{props.dettaglioImporti.map((item, index) => {
					return !item.Etichetta ? (
						<hr key={index} />
					) : (
						<div className='mt-2' key={index}>
							<div
								className={
									item.Etichetta.toLocaleLowerCase() === 'totale servizi'
										? 'app-riep-info app-riep-total'
										: item.Etichetta.toLowerCase().includes(
												'acconti bollette precedenti'
										  )
										? 'app-riep-info app-riep-advance-payment'
										: 'app-riep-info'
								}
							>
								<span>{item.Etichetta}</span>
								<span className='app-bold'>
									{item.Valore ? item.Valore + '€' : ''}
								</span>
							</div>
						</div>
					);
				})}
			</div>
			<div className='d-block d-sm-none'>
				<hr />
				{collapsed ? (
					<div
						className='app-link-no-underline app-riep-info'
						onClick={toggleCollapse}
					>
						Vedi dettaglio
						<i className='fa fa-angle-down'></i>
					</div>
				) : (
					<div
						className='app-link-no-underline app-riep-info'
						onClick={toggleCollapse}
					>
						Chiudi dettaglio
						<i className='fa fa-angle-up'></i>
					</div>
				)}
				<hr />
			</div>
			{!collapsed && (
				<div className='d-block d-sm-none'>
					{props.dettaglioImporti.map((item, index) => {
						return !item.Etichetta ? (
							<hr />
						) : (
							<div className='mt-2' key={index}>
								<div
									className={
										item.Etichetta.toLowerCase() === 'totale servizi'
											? 'app-riep-info app-riep-total'
											: item.Etichetta.toLowerCase().includes(
													'acconti bollette precedenti'
											  )
											? 'app-riep-info app-riep-advance-payment'
											: 'app-riep-info'
									}
								>
									<span>{item.Etichetta}</span>
									<span className='app-bold'>
										{item.Valore ? item.Valore + '€' : ''}
									</span>
								</div>
							</div>
						);
					})}
				</div>
			)}
		</div>
	);
};
export default AmountsSummary;
