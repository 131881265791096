type Props = {};

const ClientConsumptionFooter = (props: Props) => {
	return (
		<div id='client-consumption-footer' className='row mt-5 pt-5 pb-5 footer'>
			<div className='col-10 offset-1'>
				<h5>LEGENDA:</h5>
				<p>
					<strong>F0:</strong> dal lunedì alla domenica dalle ore 00:00 alle ore
					24:00
					<br />
					<strong>F1:</strong> dal lunedì al venerdì dalle ore 8:00 alle ore
					19:00
					<br />
					<strong>F2:</strong> dal lunedì al venerdì dalle ore 7:00 alle ore
					8:00 e dalle ore 19:00 alle ore 23:00; il sabato dalle ore 7:00 alle
					ore 23:00
					<br />
					<strong>F3:</strong> dal lunedì al sabato dalle ore 0:00 alle ore 7:00
					e dalle ore 23:00 alle ore 24:00; la domenica e i festivi tutte le ore
					della giornata
					<br />
				</p>
			</div>
		</div>
	);
};

export default ClientConsumptionFooter;
