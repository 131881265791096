import { useFormikContext } from 'formik';

import MyTextInput from '../../../../../components/MyTextInput';

type Props = {
	goBack: () => void;
};

const StolenForm = (props: Props) => {
	const { handleSubmit, isSubmitting, isValid, dirty } =
		useFormikContext<any>();

	return (
		<form onSubmit={handleSubmit}>
			<div className='input-group mb-3'>
				<MyTextInput
					className='m-auto'
					type='date'
					id='stolenDate'
					name='stolenDate'
				/>
			</div>
			<div className='input-group mb-3'>
				<div className='input-group-prepend ms-auto me-auto'>
					<button
						className='btn btn-outline-secondary btnConfirm'
						type='submit'
						disabled={isSubmitting || !isValid || !dirty}
					>
						Conferma
					</button>
				</div>
			</div>
			<div className='input-group mb-3'>
				<div className='input-group-prepend ms-auto me-auto'>
					<button
						onClick={props.goBack}
						className='btn btn-outline-secondary btnCancel'
						type='submit'
					>
						Annulla
					</button>
				</div>
			</div>
		</form>
	);
};

export default StolenForm;
