import { EtichettaValore } from '../../../../models/etichetta-valore';

type Props = {
	welcome: EtichettaValore;
	cliente: EtichettaValore;
	codiceFiscale: EtichettaValore;
	codiceUtente: EtichettaValore;
	numeroContratto?: EtichettaValore;
	indirizzo?: EtichettaValore;
	tipologiaTariffa?: EtichettaValore;
};

const Welcome = (props: Props) => {
	return (
		<div className='p-5 welcome-logo-margin-bottom'>
			<h1 className='mb-3 mb-md-5'>{props.welcome.Valore}</h1>
			<div>
				<span className='app-bold'>{props.cliente.Valore}</span>
				<p>
					{props.codiceFiscale.Etichetta}:{' '}
					<span className='app-only-bold'>{props.codiceFiscale.Valore}</span>
				</p>
			</div>
			<div className='mt-3 mt-md-5'>
				{props.codiceUtente.Etichetta}
				<p className='app-bold'>{props.codiceUtente.Valore}</p>
			</div>
			{props.numeroContratto && (
				<div>
					{props.numeroContratto.Etichetta}
					<p className='app-bold'>{props.numeroContratto.Valore}</p>
				</div>
			)}
			{props.indirizzo && (
				<div>
					{props.indirizzo.Etichetta}
					<p className='app-bold'>{props.indirizzo.Valore}</p>
				</div>
			)}
			{props.tipologiaTariffa && (
				<div>
					{props.tipologiaTariffa.Etichetta}
					<p className='app-bold'>{props.tipologiaTariffa.Valore}</p>
				</div>
			)}
		</div>
	);
};

export default Welcome;
