import React, { useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useStore } from '../../../../../context/store';
import { Credential } from '../../../../../models/credential';

type Props = {};

const Login = (props: Props) => {
	const { userStore, loaderStore } = useStore();
	const { id } = useParams<{ id: string }>();

	const navigate = useNavigate();
	const login = useCallback(
		async (id: string) => {
			loaderStore.setAppLoadingTrue();
			userStore
				.authenticate(new Credential(id, null))
				.then(() => {
					const decodedToken = userStore.getDecodedToken();
					loaderStore.setAppLoadingFalse();
					navigate(decodedToken?.url + '/' + decodedToken?.id);
				})
				.catch((error) => {
					navigate('/not-found');
					loaderStore.setAppLoadingFalse();
				});
		},
		[userStore, loaderStore, navigate]
	);

	useEffect(() => {
		if (!id) return navigate('/not-found');
		login(id!);
	}, [login, id, navigate]);

	return <></>;
};

export default Login;
