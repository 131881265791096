import { Navigate, useNavigate, useRouteLoaderData } from 'react-router-dom';

import { JsonVeritasTari } from '../models/jsonVeritas';
import agent from '../../../../../api/agent';
import { Static } from '../../../../../static';
import DocumentDetail from '../../components/DocumentDetail';
import AmountsSummary from '../../components/AmountsSummary';
import ContractDetail from './ContractDetail';

const Invoice = (props: { loader: string }) => {
	const navigate = useNavigate();
	const json = useRouteLoaderData(props.loader) as JsonVeritasTari;
	if (!json) return <Navigate to='/veritas/not-found' />;

	const key = '1';

	const fattura = key
		? json.Fatture.find((elem) => elem.key === key) || json.Fatture[0]
		: json.Fatture[0];

	const goBack = async () => {
		agent.Tracking.track(
			`${Static.goBack} ${Static.fromInvoiceComponent} ${key}`
		);
		navigate(-1);
	};

	return (
		<div id='invoice' className='app-document-list-container'>
			<div className='pt-4 pb-4 app-box-one-tari-darker'>
				<div className='row'>
					<div className='app-div-go-back mt-3 ms-5' onClick={goBack}>
						<img
							alt=''
							className='app-icon-arrow'
							src='/images/veritas/invoice//ArrowSxIcon.svg'
						/>
						Torna
					</div>
				</div>
				<div className='row'>
					<div className='col-12 col-md-6'>
						<div className='m2 me-md-1 mt-md-5 ms-md-5 mb-md-5'>
							<DocumentDetail
								importoTotaleContratto={
									fattura.RiepilogoDocumento.ImportoTotaleContratto
								}
								scadenzaBolletta={fattura.ScadenzaBolletta}
								documentoN={fattura.DocumentoN}
								datiBolletta={fattura.DatiBolletta}
								documentoPeriodo={fattura.DocumentoPeriodo}
								isSingleDocument={false}
							/>
						</div>
					</div>
					<div className='col-12 col-md-6'>
						<div className='m2 ms-md-1 mt-md-5 me-md-5 mb-md-5'>
							<AmountsSummary
								importoTotaleContratto={
									fattura.RiepilogoDocumento.ImportoTotaleContratto
								}
								dettaglioImporti={
									fattura.RiepilogoDocumento.DettaglioImportiServiziFattura
								}
							/>
						</div>
					</div>
				</div>
			</div>
			<div className='app-contracts-detail-container pt-4 mt-2'>
				{fattura.Contratti.map((item, i) => (
					<ContractDetail key={i} contratto={item} hideHR={i === 0} />
				))}
			</div>
		</div>
	);
};

export default Invoice;
