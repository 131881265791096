import { createContext, useContext } from 'react';

import UserStore from './userStore';
import ModalStore from './modalStore';
import LoaderStore from './loaderStore';

export const store = {
	userStore: new UserStore(),
	modalStore: new ModalStore(),
	loaderStore: new LoaderStore(),
};

export const StoreContext = createContext(store);

export function useStore() {
	return useContext(StoreContext);
}
