import { Formik, FormikHelpers } from 'formik';
import { object, string } from 'yup';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { Json } from '../models/json';
import { Navigation, VehicleStatus } from '../models/navigation.enum';
import MainDataForm from './MainDataForm';
import { DocumentData } from '../models/document-data';
import agent from '../../../../../api/agent';
import { toast } from 'react-toastify';
import { Static } from '../../../../../static';
import { JsonResponse, RegistryUpdate } from '../models/jsonResponse';
import Sold from './Sold';
import Stolen from './Stolen';
import Demolished from './Demolished';
import Restored from './Restored';
import UsedByThirdPart from './UsedByThirdPart';
import { useStore } from '../../../../../context/store';

type Props = {
	json: Json;
};

const validationSchema = object({
	indirizzo: string().required("L 'indirizzo è obbligatorio"),
	cap: string().required('Il CAP è obbligatorio'),
	citta: string().required('La città è obbligatoria'),
	nazione: string().required('La nazione è obbligatoria'),
});

const DataForm = (props: Props) => {
	const [viewIndex, setViewIndex] = useState(Navigation.mainView);
	const [isFrameNotSelected, setIsFrameNotSelected] = useState(false);
	const { loaderStore } = useStore();

	const frames = props.json.FrameNumbers;
	const [selectedFrame, setSelectedFrame] = useState<string>(
		frames.length === 1 ? frames[0] : ''
	);
	const { id } = useParams();

	const documentId = Number(id) || 0;

	const initialValues = {
		ragioneSociale: props.json.HolderBusinessName,
		partitaIva: props.json.HolderVAT,
		cognome: props.json.HolderSurname,
		nome: props.json.HolderName,
		codiceFiscale: props.json.HolderCF,
		indirizzo: props.json.HolderAddress,
		cap: props.json.HolderCap,
		citta: props.json.HolderCity,
		nazione: props.json.HolderState,
		telefono: props.json.HolderPhoneNumber,
		email: props.json.HolderEmail,
		pec: props.json.HolderPec,
		sitoAziendale: props.json.HolderWebSite,
	};
	const changeView = (event: number) => {
		if (selectedFrame === undefined) {
			setIsFrameNotSelected(true);
		} else {
			setViewIndex(event);
			setIsFrameNotSelected(false);
		}
	};

	const changeFrame = (value: string) => {
		setSelectedFrame(value);
		setIsFrameNotSelected(false);
	};

	const submit = async (values: any, actions: FormikHelpers<any>) => {
		let documentData = mapJsonObject(values);
		agent.Tracking.track('Click su modifica anagrafica.');
		loaderStore.setAppLoadingTrue();
		agent.Document.saveDocumentData(documentData)
			.then((res) => {
				if (res === true) {
					toast.success(Static.thanksForUpdatingYourRegistry);
				} else {
					toast.error(Static.saveFailed);
				}
				actions.setSubmitting(false);
				loaderStore.setAppLoadingFalse();
			})
			.catch(() => {
				toast.error(Static.anUnexpectedErrorHasOccurred);
				actions.setSubmitting(false);
				loaderStore.setAppLoadingFalse();
			});
	};

	const mapJsonObject = (formData: any) => {
		let jsonRegistryUpdateResponse = new JsonResponse();
		jsonRegistryUpdateResponse.CampaignDate = props.json.CampaignDate;
		jsonRegistryUpdateResponse.HolderName = props.json.HolderName;
		jsonRegistryUpdateResponse.HolderSurname = props.json.HolderSurname;
		jsonRegistryUpdateResponse.HolderBusinessName =
			props.json.HolderBusinessName;
		jsonRegistryUpdateResponse.HolderVat = props.json.HolderVAT;
		jsonRegistryUpdateResponse.HolderCF = props.json.HolderCF;
		jsonRegistryUpdateResponse.IvecoContactEmail = props.json.IvecoContactEmail;
		jsonRegistryUpdateResponse.IvecoContactPhoneNumber =
			props.json.IvecoContactNumber;
		jsonRegistryUpdateResponse.Language = props.json.LanguageFlag;
		jsonRegistryUpdateResponse.CampaignId = props.json.CampaignId;
		jsonRegistryUpdateResponse.VehicleStatus =
			VehicleStatus[VehicleStatus.RegistryUpdate];
		jsonRegistryUpdateResponse.VehicleStatusId =
			VehicleStatus.RegistryUpdate.toString();
		jsonRegistryUpdateResponse.CampaignDescription =
			props.json.CampaignDescription;
		jsonRegistryUpdateResponse.HolderAddress = props.json.HolderAddress;
		jsonRegistryUpdateResponse.HolderCap = props.json.HolderCap;
		jsonRegistryUpdateResponse.HolderCity = props.json.HolderCity;
		jsonRegistryUpdateResponse.HolderState = props.json.HolderState;
		jsonRegistryUpdateResponse.HolderEmail = props.json.HolderEmail;
		jsonRegistryUpdateResponse.HolderPec = props.json.HolderPec;
		jsonRegistryUpdateResponse.HolderWebSite = props.json.HolderWebSite;
		jsonRegistryUpdateResponse.HolderPhoneNumber = props.json.HolderPhoneNumber;

		let jsonRegistryUpdate = new RegistryUpdate();
		jsonRegistryUpdate.UpdatedAddress = formData.indirizzo;
		jsonRegistryUpdate.UpdatedCap = formData.cap;
		jsonRegistryUpdate.UpdatedCity = formData.citta;
		jsonRegistryUpdate.UpdatedState = formData.nazione;
		jsonRegistryUpdate.UpdatedPhoneNumber = formData.telefono;
		jsonRegistryUpdate.UpdatedEmail = formData.email;
		jsonRegistryUpdate.UpdatedPec = formData.pec;
		jsonRegistryUpdate.UpdatedWebSite = formData.sitoAziendale;
		jsonRegistryUpdate.UpdatedName = formData.nome;
		jsonRegistryUpdate.UpdatedSurname = formData.cognome;
		jsonRegistryUpdate.UpdatedBusinessName = formData.ragioneSociale;
		jsonRegistryUpdate.UpdatedVat = formData.partitaIva;
		jsonRegistryUpdate.UpdatedCF = formData.codiceFiscale;
		jsonRegistryUpdate.UpdatedFramesNumber = frames.join();
		jsonRegistryUpdateResponse.RegistryUpdateDetails = jsonRegistryUpdate;

		let documentData = new DocumentData();
		documentData.documentId = documentId;
		documentData.json = JSON.stringify(jsonRegistryUpdateResponse);

		return documentData;
	};

	return (
		<div>
			{viewIndex === 1 && (
				<div>
					<div className='mt-5'>
						<p className='menuTitle'>DATI AZIENDALI</p>
					</div>
					<div className='row formGroup g-0'>
						<Formik
							validateOnMount
							enableReinitialize
							initialValues={initialValues}
							validationSchema={validationSchema}
							onSubmit={submit}
						>
							<MainDataForm />
						</Formik>
					</div>
					<div className='row mt-5 ms-auto me-auto'>
						<p className='menuTitle'>VARIAZIONI DI POSSESSO</p>
					</div>
					<div className='row menuText'>
						Nel caso il veicolo non fosse più in suo possesso, Le chiediamo di
						indicarci qui di seguito i dati del nuovo proprietario o
						dell’utilizzatore.
						<br />
						Per il veicolo con telaio N.
					</div>
					<div className='menuText'>
						{frames.length === 1 && <div>{selectedFrame}</div>}
						{frames.length > 1 && (
							<div>
								{/* <form formGroup={formFrame}> */}
								<select
									className='dropDown'
									onChange={(e) => changeFrame(e.target.value)}
									name='frame'
								>
									<option value='' disabled>
										Seleziona il telaio
									</option>
									{frames.map((frame) => (
										<option value={frame}>{frame}</option>
									))}
								</select>
								{/* </form> */}
							</div>
						)}
						<div>
							Le chiediamo di selezionare lo stato attuale del veicolo da quanto
							segue:
						</div>
					</div>
					{isFrameNotSelected && (
						<div className='advisingMsg'>Selezionare un telaio</div>
					)}

					<div id='mainView'>
						<div className='row buttonMenu'>
							<button onClick={() => changeView(2)}>Venduto</button>
							<button onClick={() => changeView(4)}>Rubato</button>
							<button onClick={() => changeView(5)}>Demolito</button>
							<button onClick={() => changeView(6)}>Risanato</button>
						</div>
					</div>

					<div id='mainView'>
						<div className='menuText'>
							Nel caso in cui Lei sia l’Utilizzatore del mezzo, Le chiediamo
							cortesemente di indicarci i dati del proprietario:
						</div>

						<div className='row buttonMenu mb-2'>
							<button onClick={() => changeView(3)}>In uso a terzi</button>
						</div>
					</div>
				</div>
			)}

			{viewIndex === 2 && (
				<div>
					<Sold
						json={props.json}
						documentId={documentId}
						selectedFrame={selectedFrame}
						changeView={changeView}
					/>
				</div>
			)}
			{viewIndex === 4 && (
				<div>
					<Stolen
						json={props.json}
						documentId={documentId}
						selectedFrame={selectedFrame}
						changeView={changeView}
					/>
				</div>
			)}
			{viewIndex === 5 && (
				<div>
					<Demolished
						json={props.json}
						documentId={documentId}
						selectedFrame={selectedFrame}
						changeView={changeView}
					/>
				</div>
			)}
			{viewIndex === 6 && (
				<div>
					<Restored
						json={props.json}
						documentId={documentId}
						selectedFrame={selectedFrame}
						changeView={changeView}
					/>
				</div>
			)}
			{viewIndex === 3 && (
				<div>
					<UsedByThirdPart
						json={props.json}
						documentId={documentId}
						selectedFrame={selectedFrame}
						changeView={changeView}
					/>
				</div>
			)}
		</div>
	);
};

export default DataForm;
