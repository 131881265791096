import { date, object } from 'yup';
import { Formik, FormikHelpers } from 'formik';
import { toast } from 'react-toastify';

import { Json } from '../models/json';
import agent from '../../../../../api/agent';
import { Navigation, VehicleStatus } from '../models/navigation.enum';
import { Static } from '../../../../../static';
import { JsonResponse, VehicleStolen } from '../models/jsonResponse';
import { DocumentData } from '../models/document-data';
import StolenForm from './StolenForm';
import { useStore } from '../../../../../context/store';

type Props = {
	json: Json;
	documentId: number;
	selectedFrame: string;
	changeView: (n: number) => void;
};

const validationSchema = object({
	stolenDate: date().required('La data di furto è obbligatoria'),
});

const Stolen = (props: Props) => {
	const { loaderStore } = useStore();
	const initialValues = {
		stolenDate: '',
	};

	const submit = async (values: any, actions: FormikHelpers<any>) => {
		let documentData = mapJsonObject(values);
		loaderStore.setAppLoadingTrue();
		agent.Document.saveDocumentData(documentData)
			.then((res) => {
				if (res === true) {
					toast.success(Static.thanks);
					props.changeView(Navigation.mainView);
				} else toast.error(Static.saveFailed);

				actions.setSubmitting(false);
				loaderStore.setAppLoadingFalse();
			})
			.catch((ex) => {
				actions.setSubmitting(false);
				loaderStore.setAppLoadingFalse();
				toast.error(Static.anUnexpectedErrorHasOccurred);
			});
	};

	const mapJsonObject = (formData: any) => {
		let jsonStolenResponse = new JsonResponse();
		jsonStolenResponse.CampaignDate = props.json.CampaignDate;
		jsonStolenResponse.HolderName = props.json.HolderName;
		jsonStolenResponse.HolderSurname = props.json.HolderSurname;
		jsonStolenResponse.HolderBusinessName = props.json.HolderBusinessName;
		jsonStolenResponse.HolderVat = props.json.HolderVAT;
		jsonStolenResponse.HolderCF = props.json.HolderCF;
		jsonStolenResponse.IvecoContactEmail = props.json.IvecoContactEmail;
		jsonStolenResponse.IvecoContactPhoneNumber = props.json.IvecoContactNumber;
		jsonStolenResponse.Language = props.json.LanguageFlag;
		jsonStolenResponse.FrameNumber = props.selectedFrame;
		jsonStolenResponse.CampaignId = props.json.CampaignId;
		jsonStolenResponse.VehicleStatus = VehicleStatus[VehicleStatus.Stolen];
		jsonStolenResponse.VehicleStatusId = VehicleStatus.Stolen.toString();
		jsonStolenResponse.CampaignDescription = props.json.CampaignDescription;
		jsonStolenResponse.HolderAddress = props.json.HolderAddress;
		jsonStolenResponse.HolderCap = props.json.HolderCap;
		jsonStolenResponse.HolderCity = props.json.HolderCity;
		jsonStolenResponse.HolderState = props.json.HolderState;
		jsonStolenResponse.HolderEmail = props.json.HolderEmail;
		jsonStolenResponse.HolderPec = props.json.HolderPec;
		jsonStolenResponse.HolderWebSite = props.json.HolderWebSite;
		jsonStolenResponse.HolderPhoneNumber = props.json.HolderPhoneNumber;
		let jsonStolen = new VehicleStolen();
		jsonStolen.StolenDate = formData.stolenDate;

		jsonStolenResponse.VehicleStolenDetails = jsonStolen;

		let documentData = new DocumentData();
		documentData.documentId = props.documentId;
		documentData.json = JSON.stringify(jsonStolenResponse);

		return documentData;
	};

	const goBack = () => {
		props.changeView(Navigation.mainView);
	};

	return (
		<div id='stolenForm' className='formGroup mt-5'>
			<div className='row g-0 menuTitle'>Veicolo rubato in data:</div>
			<Formik
				validateOnMount
				enableReinitialize
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={submit}
			>
				<StolenForm goBack={goBack} />
			</Formik>
			<div className='row menuText'>
				Le chiediamo gentilmente di inviarci copia del verbale di furto
				all’indirizzo mail: PIP_Iveco_Italia@pec.cnhind.com o direttamente via
				fax al numero 011 2740972.
			</div>
		</div>
	);
};

export default Stolen;
