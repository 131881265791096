import { toast } from 'react-toastify';
import { useParams } from 'react-router';

import { useStore } from '../../../../../context/store';
import { ConfirmContainer } from '../../../../../components/modal/ConfirmContainer';
import { Static } from '../../../../../static';
import agent from '../../../../../api/agent';

type Props = {};

const MainFooter = (props: Props) => {
	const { modalStore } = useStore();
	const { documentId } = useParams();
	const onUnsubscribeConfirm = () => {
		modalStore.openModal(
			<ConfirmContainer
				title=''
				message={Static.unsubscribeDialog}
				btnOkText=''
				btnCancelText=''
				btnStyle='app-btn-success'
				titleStyle='black'
				handleConfirm={async () => {
					agent.Document.unsubscribe(Number(documentId))
						.then((res) => {
							if (res === true) toast.success(Static.unsubscribeCompleted);
							else toast.error(Static.unsubscribeFailed);
						})
						.catch(() => toast.error(Static.anUnexpectedErrorHasOccurred));
				}}
				handleDecline={modalStore.closeModal}
			/>
		);
		agent.Tracking.track(
			Static.onUnsubscribeConfirm + ' ' + Static.fromMainFooterComponent
		);
	};

	const onLinkedinClick = () => {
		window.open(Static.urlDemoLinkedin, '_blank');
		agent.Tracking.track(
			Static.onLinkedinClick + ' ' + Static.fromMainFooterComponent,
			Static.urlDemoLinkedin
		);
	};

	const onYoutubeClick = () => {
		window.open(Static.urlDemoYouTube, '_blank');
		agent.Tracking.track(
			Static.onYoutubeClick + ' ' + Static.fromMainFooterComponent,
			Static.urlDemoYouTube
		);
	};

	return (
		<div
			id='main-footer'
			className='row mt-3 mt-md-5 pt-4 pb-4 pt-md-5 pb-md-5 footer'
		>
			<div className='col-md-6'>
				<div className='ms-5'>
					<h3 className='white'>Contatti</h3>
					<h5 className='white app-no-weight'>www.prtspa.com</h5>
					<h5 className='white app-no-weight'>info@prtspa.com</h5>
					<h5 className='white app-no-weight'>(+39) 011 32 07 911</h5>
					<h5 className='white app-no-weight'>
						Via Frejus, 5 - 10092 Beinasco TO
					</h5>
				</div>
			</div>
			<div className='col-md-6'>
				<div className='ms-5'>
					<h3 className='white'>Seguici su</h3>
					<img
						className='app-pointer app-img-social me-3'
						onClick={onLinkedinClick}
						src='/images/shared/LinkedinWhite.png'
						alt='Linkedin'
					/>
					<img
						className='app-pointer app-img-social'
						onClick={onYoutubeClick}
						src='/images/shared/YouTubeWhite.png'
						alt='Youtube'
					/>
					<p className='pt-2 pe-4 pt-md-3 pb-0 mb-0 white'>
						<strong onClick={onUnsubscribeConfirm} className='app-pointer'>
							Clicca qui{' '}
						</strong>
						se non vuoi più ricevere la tua bolletta in formato elettronico.
					</p>
				</div>
			</div>
		</div>
	);
};

export default MainFooter;
