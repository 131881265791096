import { useField } from 'formik';

const MyTextInput = ({ ...props }) => {
	const [field, meta] = useField(props.name);
	return (
		<>
			<div className='m-auto'>
				<input
					{...field}
					{...props}
					className={
						props.className +
						(meta.touched && meta.error
							? ' is-invalid'
							: !field.value
							? ' borderOrange'
							: '')
					}
				/>
			</div>
			{meta.touched && meta.error ? (
				<span className='text-center error text-danger mt-1'>{meta.error}</span>
			) : null}
		</>
	);
};

export default MyTextInput;
