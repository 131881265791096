import { Navigate, useRouteLoaderData } from 'react-router';

import { Json } from '../models/json';

type Props = {};

const ClientDetail = (props: Props) => {
	const json = useRouteLoaderData('demoJsonLoader') as Json;
	if (!json) return <Navigate to='/not-found' />;
	return (
		<div id='client-detail' className='mt-4 pb-5'>
			<div className='row'>
				<div className='col-md-6'>
					<div className='m-4 mt-2'>
						<div className='card app-card-green border border-3 p-3 p-md-5'>
							<h3 className='green'>Dati cliente</h3>
							<h5>
								<span className='black'>Intestatario contratto </span>
								{json.Cliente}
							</h5>
							<h5>
								<span className='black'>Cod. Fiscale/P.IVA </span>
								{json.CodiceFiscale}
							</h5>
							<h3 className='green'>
								{json.PuntoEtichetta}{' '}
								<span style={{ fontWeight: '700' }}>{json.PuntoValore}</span>
							</h3>
						</div>
					</div>

					<div className='m-4 mt-2'>
						<div className='card p-3 border border-3 border-success p-md-5'>
							<h3 className='green'>Contratto Cliente</h3>
							{json.DatiContratto?.map((val) => (
								<p>
									{val.Etichetta} <br />{' '}
									<span style={{ fontWeight: '700' }}>{val.Valore}</span>
								</p>
							))}
						</div>
					</div>
				</div>
				<div className='col-md-6'>
					<div className='m-4 mt-2'>
						<div className='card p-3 border border-3 border-success p-md-5'>
							<h3 className='green'>Dati Fornitura</h3>
							{json.DatiFornitura?.map((val) => (
								<p>
									{val.Etichetta} <br />{' '}
									<span style={{ fontWeight: '700' }}> {val.Valore}</span>
								</p>
							))}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ClientDetail;
