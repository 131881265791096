import React from 'react';
import { Static } from '../../../../../static';

const Footer = () => {
	const onCookieClick = async () => {
		window.open(Static.urlDemoPrivacy, '_blank');
	};

	return (
		<div className='ms-5 me-5 mt-3'>
			<p className='app-p-bigger'>
				<span className='app-bold'>P.R.T S.p.A.</span> Sede legale in Beinasco
				(TO), via Frejus n.5 Capitale sociale € 416.000,00 i.v. - R.E.A. di
				Torino n. 450894 Registro Imprese di Torino e C.F n. 00487700015 - P.I.
				IT00487700015{' - '}
				<span className='app-link' onClick={onCookieClick}>
					Cookie Policy
				</span>
			</p>
		</div>
	);
};

export default Footer;
