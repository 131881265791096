import { Navigate, useRouteLoaderData } from 'react-router-dom';

import DownloadPdf from './DownloadPdf';
import { JsonVeritasTari } from '../tari/models/jsonVeritas';
import { JsonVeritasIdrico } from '../idrico/models/jsonVeritas';

interface Props {
	isTari: boolean;
	loaderTari: string;
	loaderIdrico: string;
}

const Header = (props: Props) => {
	let jsonTari = useRouteLoaderData(props.loaderTari) as JsonVeritasTari;

	let jsonIdrico = useRouteLoaderData(props.loaderIdrico) as JsonVeritasIdrico;

	const json = props.isTari ? jsonTari : jsonIdrico;
	if (!json) return <Navigate to='/not-found' />;

	return (
		<div
			id='header'
			className='row sticky-top bg-white pt-3 pb-3 pt-md-4 pb-md-4 ps-md-5'
		>
			<div className='col-4 col-md-3 col-xl-3 align-self-center'>
				<img
					alt=''
					className='app-max-height ms-3'
					src='/images/veritas/invoice/VeritasLogo.svg'
				/>
			</div>
			<div className='col-8 col-md align-self-center'>
				<div className='app-header-idrico float-end'>
					<img
						alt=''
						className='d-none d-xl-block'
						src={`/images/veritas/invoice/${
							props.isTari ? 'VeritasTariIcon.svg' : 'VeritasIdricoIcon.svg'
						}`}
					/>
					<div className='me-3 me-md-0 ms-md-2 app-servizio-idrico'>
						<span className='app-bold'>{json.TipoBolletta.Valore}</span>
						<span>{json.TipoBolletta.Etichetta}</span>
					</div>
				</div>
			</div>
			<div className='col-12 col-md-auto align-self-center'>
				<DownloadPdf
					className='float-end me-3 mt-2 mt-md-0 me-md-5'
					datiBolletta={json.Fatture[0].DatiBolletta}
				/>
			</div>
		</div>
	);
};

export default Header;
