import { useStore } from '../../../../../../context/store';
import agent from '../../../../../../api/agent';
import { Static } from '../../../../../../static';

const SelfReading = () => {
	const { modalStore } = useStore();

	const onSubmit = () => {
		modalStore.closeModal();
		agent.Tracking.track(
			Static.submitModalSelfReading + ' ' + Static.fromModalSelfReadingComponent
		);
	};

	const closeModal = () => {
		modalStore.closeModal();
		agent.Tracking.track(
			Static.closeModalSelfReading + ' ' + Static.fromModalSelfReadingComponent
		);
	};

	return (
		<div id='self-reading'>
			<div className='pb-0 modal-header text-center'>
				<div>
					<h3 className='modal-title text-center'>Autolettura</h3>
				</div>
			</div>

			<div className='modal-body'>
				<form>
					<div className='form-group'>
						<label htmlFor='exampleInputConsumption'>Consumi</label>
						<input
							type='number'
							className='form-control'
							id='exampleInputConsumption'
							aria-describedby='consumptionHelp'
							placeholder='400'
						/>
						<small id='consumptionHelp' className='form-text text-muted'>
							Indica la tua autolettura.
						</small>
					</div>
					<div className='form-check'>
						<input
							type='checkbox'
							className='form-check-input'
							id='exampleCheck'
						/>
						<label className='form-check-label' htmlFor='exampleCheck'>
							Acconsento al trattamento dei dati personali.
						</label>
					</div>
					<button
						type='submit'
						className='btn app-btn-success mt-1 mt-md-3'
						onClick={onSubmit}
					>
						INVIA
					</button>
				</form>
			</div>

			<div className='modal-footer'>
				<button
					onClick={closeModal}
					className='app-pointer btn app-height-navicon'
				>
					<i className='fa fa-arrow-left green'></i>
				</button>
			</div>
		</div>
	);
};

export default SelfReading;
