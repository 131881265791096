import { makeAutoObservable } from 'mobx';

export default class LoaderStore {
	appLoading = false;

	constructor() {
		makeAutoObservable(this);
	}

	setAppLoadingTrue = () => {
		this.appLoading = true;
	};

	setAppLoadingFalse = () => {
		this.appLoading = false;
	};
}
