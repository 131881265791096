import { Navigate, useRouteLoaderData } from 'react-router-dom';

import { JsonVeritasTari } from '../models/jsonVeritas';
import Welcome from '../../components/Welcome';
import DocumentDetail from '../../components/DocumentDetail';
import CollectionCenter from '../../components/CollectionCenter';
import ReferenceDesk from '../../components/ReferenceDesk';
import PaymentDetail from '../../components/PaymentDetail';
import ContractDetail from './ContractDetail';
import AmountsSummary from '../../components/AmountsSummary';

const Document = (props: { loader: string }) => {
	const json = useRouteLoaderData(props.loader) as JsonVeritasTari;
	if (!json) return <Navigate to='/veritas/not-found' />;

	return (
		<div id='document'>
			<div className='row pt-4 pb-4 app-box-one-tari'>
				<div className='col-12 col-md-6 col-xl-7'>
					<div className='m-2 m-md-5'>
						<Welcome
							welcome={json.Customer.Welcome}
							cliente={json.Customer.Cliente}
							codiceFiscale={json.Customer.CodiceFiscale}
							codiceUtente={json.Customer.CodiceUtente}
							numeroContratto={json.Fatture[0].Contratti[0].NumeroContratto}
							indirizzo={json.Fatture[0].Contratti[0].Indirizzo}
							tipologiaTariffa={json.Fatture[0].Contratti[0].TipologiaTariffa}
						/>
					</div>
				</div>
				<div className='col-12 col-md-6 col-xl-5'>
					<div className='m-2 ms-md-1 mt-md-5 me-md-5 mb-md-5'>
						<DocumentDetail
							importoTotaleContratto={
								json.Fatture[0].RiepilogoDocumento.ImportoTotaleContratto
							}
							scadenzaBolletta={json.Fatture[0].ScadenzaBolletta}
							documentoN={json.Fatture[0].DocumentoN}
							datiBolletta={json.Fatture[0].DatiBolletta}
							documentoPeriodo={json.Fatture[0].DocumentoPeriodo}
							isSingleDocument={true}
						/>
					</div>
				</div>
			</div>
			<div id='detail' className='row pt-4'>
				<div className='col-12 col-md-6'>
					<div className='m-2 me-md-1 mt-md-5 ms-md-5 mb-md-5'>
						{json.Fatture[0].Ecocentro && (
							<CollectionCenter centroRaccolta={json.Fatture[0].Ecocentro} />
						)}
					</div>
					<div className='m-2 me-md-1 mt-md-5 ms-md-5 mb-md-5'>
						<ReferenceDesk sportello={json.Fatture[0].Sportello} />
					</div>
				</div>
				<div className='col-12 col-md-6'>
					<div className='m-2 mb-4 ms-md-1 mt-md-5 me-md-5 mb-md-5'>
						<PaymentDetail payment={json.Pagamenti} />
					</div>
				</div>
			</div>
			<div className='app-contracts-detail-container pb-4'>
				{json.Fatture[0].Contratti.map((contract, index) => (
					<ContractDetail key={index} contratto={contract} />
				))}
			</div>
			<div className='row pt-4 pb-4 app-box-two-tari'>
				<div className='col'></div>
				<div className='col-12 col-md-6'>
					<div className='m-2 ms-md-1 mt-md-5 me-md-5 mb-md-5'>
						<AmountsSummary
							importoTotaleContratto={
								json.Fatture[0].RiepilogoDocumento.ImportoTotaleContratto
							}
							dettaglioImporti={
								json.Fatture[0].RiepilogoDocumento
									.DettaglioImportiServiziFattura
							}
						/>
					</div>
				</div>
				<div className='col'></div>
			</div>
		</div>
	);
};

export default Document;
