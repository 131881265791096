import { Static } from '../../../../static';
import agent from '../../../../api/agent';

const Footer = () => {
	const onCookieClick = async () => {
		window.open(Static.urlVeritasPrivacy, '_blank');

		agent.Tracking.track(Static.fromFooterComponent, Static.urlVeritasSOL);
	};

	return (
		<div className='ms-5 me-5 mt-3'>
			<p className='app-p-bigger'>
				<span className='app-bold'>Veritas S.p.A.</span> P.IVA 03341820276 Santa
				Croce 489 30135 Venezia -{' '}
				<span className='app-link' onClick={onCookieClick}>
					Cookie Policy
				</span>
			</p>
		</div>
	);
};

export default Footer;
