import { Spinner } from 'react-bootstrap';

import { DatiBolletta } from '../models/dati-bolletta';
import { useState } from 'react';
import agent from '../../../../../api/agent';
type Props = { datiBolletta: DatiBolletta; className?: string };

function DownloadPdf(props: Props) {
	const [loading, setLoading] = useState(false);

	const downloadPdf = async () => {
		agent.Document.getEncodedUrl(props.datiBolletta)
			.then((url) => {
				setLoading(false);
				window.open(url, '_blank');
			})
			.catch(() => {
				setLoading(false);
			});
	};

	return (
		<button
			id='download-pdf'
			className={`btn app-btn-download ${props.className || ''}`}
			onClick={downloadPdf}
		>
			{loading ? (
				<Spinner
					as='span'
					animation='border'
					size='sm'
					role='status'
					aria-hidden='true'
				/>
			) : (
				<>
					<img
						alt=''
						className='app-icon-download'
						src='/images/demo/invoice/DownloadIcon.svg'
					/>
					Scarica la bolletta
				</>
			)}
		</button>
	);
}

export default DownloadPdf;
