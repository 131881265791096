import { useStore } from '../../../../../../context/store';
import agent from '../../../../../../api/agent';
import { Static } from '../../../../../../static';

const MyRequests = () => {
	const { modalStore } = useStore();

	const onSubmit = () => {
		modalStore.closeModal();
		agent.Tracking.track(
			Static.submitModalMyRequests + ' ' + Static.fromModalMyRequestsComponent
		);
	};

	const closeModal = () => {
		modalStore.closeModal();
		agent.Tracking.track(
			Static.closeModalMyRequests + ' ' + Static.fromModalMyRequestsComponent
		);
	};

	return (
		<div id='my-requests'>
			<div className='pb-0 modal-header text-center'>
				<div>
					<h3 className='modal-title text-center'>Le mie richieste</h3>
				</div>
			</div>

			<div className='modal-body'>
				<form>
					<div className='form-group'>
						<label htmlFor='exampleInputEmail'>Indirizzo Email</label>
						<input
							type='email'
							className='form-control'
							id='exampleInputEmail'
							aria-describedby='emailHelp'
							placeholder='Inserisci una email'
						/>
						<small id='emailHelp' className='form-text text-muted'>
							Non la condivideremo con nessuno.
						</small>
					</div>
					<div className='form-group'>
						<label htmlFor='exampleInputNotes'>Note</label>
						<textarea
							typeof='text'
							className='form-control'
							id='exampleInputNotes'
							aria-describedby='notesHelp'
							placeholder='Le tue note'
							rows={3}
						></textarea>
						<small id='notesHelp' className='form-text text-muted'>
							Inserisci qui le tue note.
						</small>
					</div>
					<button
						type='submit'
						className='btn app-btn-success mt-1 mt-md-3'
						onClick={onSubmit}
					>
						INVIA
					</button>
				</form>
			</div>

			<div className='modal-footer'>
				<button
					onClick={closeModal}
					type='button'
					className='app-pointer btn app-height-navicon'
				>
					<i className='fa fa-arrow-left green'></i>
				</button>
			</div>
		</div>
	);
};

export default MyRequests;
