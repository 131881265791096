import { useFormikContext } from 'formik';

import MyTextInput from '../../../../../components/MyTextInput';

const MainDataForm = () => {
	const { handleSubmit, isSubmitting, isValid } = useFormikContext<any>();

	return (
		<form onSubmit={handleSubmit}>
			<div className='input-group mb-3'>
				<MyTextInput
					type='text'
					className='form-control'
					placeholder='Ragione Sociale'
					name='ragioneSociale'
				/>
			</div>
			<div className='input-group mb-3'>
				<MyTextInput
					type='text'
					className='form-control'
					placeholder='Partita Iva'
					name='partitaIva'
				/>
			</div>
			<div className='input-group mb-3'>
				<MyTextInput
					type='text'
					className='form-control'
					placeholder='Cognome'
					name='cognome'
				/>
			</div>
			<div className='input-group mb-3'>
				<MyTextInput
					type='text'
					className='form-control'
					placeholder='Nome'
					name='nome'
				/>
			</div>
			<div className='input-group mb-3'>
				<MyTextInput
					type='text'
					className='form-control'
					placeholder='Codice Fiscale'
					name='codiceFiscale'
				/>
			</div>
			<div className='input-group mb-3'>
				<MyTextInput
					type='text'
					className='form-control'
					placeholder='Indirizzo'
					name='indirizzo'
				/>
			</div>
			<div className='input-group mb-3'>
				<MyTextInput
					type='text'
					className='form-control'
					placeholder='CAP'
					name='cap'
				/>
			</div>
			<div className='input-group mb-3'>
				<MyTextInput
					type='text'
					className='form-control'
					placeholder='Città'
					name='citta'
				/>
			</div>
			<div className='input-group mb-3'>
				<MyTextInput
					type='text'
					className='form-control'
					placeholder='Nazione'
					name='nazione'
				/>
			</div>
			<div className='input-group mb-3'>
				<MyTextInput
					type='text'
					className='form-control'
					placeholder='Telefono'
					name='telefono'
				/>
			</div>
			<div className='input-group mb-3'>
				<MyTextInput
					type='text'
					className='form-control'
					placeholder='Email'
					name='email'
				/>
			</div>
			<div className='input-group mb-3'>
				<MyTextInput
					type='text'
					className='form-control'
					placeholder='PEC'
					name='pec'
				/>
			</div>
			<div className='input-group mb-3'>
				<MyTextInput
					type='text'
					className='form-control'
					placeholder='Sito Aziendale'
					name='sitoAziendale'
				/>
			</div>
			<div className='input-group mb-3'>
				<div className='input-group-prepend ms-auto me-auto'>
					<button
						className='btn btn-outline-secondary btnConfirm'
						type='submit'
						disabled={isSubmitting || !isValid}
					>
						Conferma
					</button>
				</div>
			</div>
		</form>
	);
};

export default MainDataForm;
