import { date, object } from 'yup';
import { Formik, FormikHelpers } from 'formik';
import { toast } from 'react-toastify';

import { Json } from '../models/json';
import agent from '../../../../../api/agent';
import { Navigation, VehicleStatus } from '../models/navigation.enum';
import { Static } from '../../../../../static';
import { JsonResponse, VehicleDemolished } from '../models/jsonResponse';
import { DocumentData } from '../models/document-data';
import DemolishedForm from './DemolishedForm';
import { useStore } from '../../../../../context/store';

type Props = {
	json: Json;
	documentId: number;
	selectedFrame: string;
	changeView: (n: number) => void;
};

const validationSchema = object({
	demolishedDate: date().required('La data di demolizione è obbligatoria'),
});

const Demolished = (props: Props) => {
	const { loaderStore } = useStore();
	const initialValues = {
		demolishedDate: '',
	};

	const submit = async (values: any, actions: FormikHelpers<any>) => {
		let documentData = mapJsonObject(values);
		loaderStore.setAppLoadingTrue();
		agent.Document.saveDocumentData(documentData)
			.then((res) => {
				if (res === true) {
					toast.success(Static.thanks);
					props.changeView(Navigation.mainView);
				} else toast.error(Static.saveFailed);
				actions.setSubmitting(false);
				loaderStore.setAppLoadingFalse();
			})
			.catch((ex) => {
				actions.setSubmitting(false);
				loaderStore.setAppLoadingFalse();
				toast.error(Static.anUnexpectedErrorHasOccurred);
			});
	};

	const mapJsonObject = (formData: any) => {
		let jsonDemolishedResponse = new JsonResponse();
		jsonDemolishedResponse.CampaignDate = props.json.CampaignDate;
		jsonDemolishedResponse.HolderName = props.json.HolderName;
		jsonDemolishedResponse.HolderSurname = props.json.HolderSurname;
		jsonDemolishedResponse.HolderBusinessName = props.json.HolderBusinessName;
		jsonDemolishedResponse.HolderVat = props.json.HolderVAT;
		jsonDemolishedResponse.HolderCF = props.json.HolderCF;
		jsonDemolishedResponse.IvecoContactEmail = props.json.IvecoContactEmail;
		jsonDemolishedResponse.IvecoContactPhoneNumber =
			props.json.IvecoContactNumber;
		jsonDemolishedResponse.Language = props.json.LanguageFlag;
		jsonDemolishedResponse.FrameNumber = props.selectedFrame;
		jsonDemolishedResponse.CampaignId = props.json.CampaignId;
		jsonDemolishedResponse.VehicleStatus =
			VehicleStatus[VehicleStatus.Demolished];
		jsonDemolishedResponse.VehicleStatusId =
			VehicleStatus.Demolished.toString();
		jsonDemolishedResponse.CampaignDescription = props.json.CampaignDescription;
		jsonDemolishedResponse.HolderAddress = props.json.HolderAddress;
		jsonDemolishedResponse.HolderCap = props.json.HolderCap;
		jsonDemolishedResponse.HolderCity = props.json.HolderCity;
		jsonDemolishedResponse.HolderState = props.json.HolderState;
		jsonDemolishedResponse.HolderEmail = props.json.HolderEmail;
		jsonDemolishedResponse.HolderPec = props.json.HolderPec;
		jsonDemolishedResponse.HolderWebSite = props.json.HolderWebSite;
		jsonDemolishedResponse.HolderPhoneNumber = props.json.HolderPhoneNumber;

		let jsonDemolished = new VehicleDemolished();
		jsonDemolished.DemolishedDate = formData.demolishedDate;

		jsonDemolishedResponse.VehicleDemolishedDetails = jsonDemolished;

		let documentData = new DocumentData();
		documentData.documentId = props.documentId;
		documentData.json = JSON.stringify(jsonDemolishedResponse);

		return documentData;
	};

	const goBack = () => {
		props.changeView(Navigation.mainView);
	};

	return (
		<div id='demolishedVehicle' className='formGroup mt-5'>
			<div className='row menuTitle g-0'>Veicolo rottamato in data:</div>
			<Formik
				validateOnMount
				enableReinitialize
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={submit}
			>
				<DemolishedForm goBack={goBack} />
			</Formik>
			<div className='row menuText'>
				Le chiediamo gentilmente di inviarci copia della documentazione di
				rottamazione all’indirizzo mail: PIP_Iveco_Italia@pec.cnhind.com o
				direttamente via fax al numero 011 2740972.
			</div>
		</div>
	);
};

export default Demolished;
