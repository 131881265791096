import { useEffect } from 'react';

import { useStore } from '../../../../context/store';

const NotFound = () => {
	const { loaderStore } = useStore();

	useEffect(() => loaderStore.setAppLoadingFalse, [loaderStore]);

	return (
		<div className='d-flex fullscreen-gradient mx-2 mx-md-0 flex-column justify'>
			<div className='mx-auto mt-5 text-center'>
				<img
					alt=''
					className='w-50'
					src='/images/veritas/invoice/VeritasLogo.svg'
				/>
			</div>
			<div className='card mx-auto mt-5'>
				<div className='card p-5 text-center app-bold'>
					<h1>
						4<span className='light-blue'>0</span>4
					</h1>
					<h2>Scusa</h2>
					<h5>non riusciamo a trovare la pagina che stai cercando</h5>
				</div>
			</div>
		</div>
	);
};

export default NotFound;
