import { useState } from 'react';

import agent from '../../../../api/agent';
import { Static } from '../../../../static';
import { Pagamenti } from '../models/payment';

type Props = {
	payment: Pagamenti;
};

const PaymentDetail = (props: Props) => {
	const [showDetails, setShowDetails] = useState(false);

	const toggleDetails = async () => {
		agent.Tracking.track(
			`${Static.toggleDetail} ${Static.fromPaymentComponent}`
		);

		setShowDetails((prev) => !prev);
	};

	return (
		<>
			<div className='card app-card-with-background ps-5 pe-5 pt-3 pb-3 mb-3 d-block d-sm-none'>
				<div className='app-toggle-show' onClick={toggleDetails}>
					<h4 className='mb-0'>PAGAMENTO</h4>
					<i
						className={showDetails ? 'fa fa-angle-up' : 'fa fa-angle-down'}
						style={{ fontSize: '1.5rem' }}
					></i>
				</div>
			</div>
			{showDetails ? (
				<div className='card app-card-with-background p-5 d-block d-sm-none'>
					{props.payment.PagamentiRegolari && (
						<div className='app-info-div'>
							<img
								alt=''
								className='app-info-icon'
								src='/images/veritas/invoice//HappyFaceIcon.svg'
							/>
							<div className='ms-2'>
								<span> {props.payment.PagamentiRegolari.Etichetta} </span>
								<br />
								<span className='app-bold'>
									{props.payment.PagamentiRegolari.Valore}
								</span>
							</div>
						</div>
					)}
					{props.payment.PagamentiNonRegolari && (
						<div className='app-info-div mt-4'>
							<img
								alt=''
								className='app-info-icon'
								src='/images/veritas/invoice//SadFaceIcon.svg'
							/>
							<div className='ms-2'>
								<span> {props.payment.PagamentiNonRegolari.Etichetta} </span>
								<br />
								<span className='app-bold'>
									{props.payment.PagamentiNonRegolari.Valore}
								</span>
								<br />
								{props.payment.PagamentiNonRegolari.DettaglioNP.map(
									(item, index) => (
										<div key={index}>{item.Valore}</div>
									)
								)}
							</div>
						</div>
					)}
					<div className='app-info-div mt-4'>
						<img
							alt=''
							className='app-info-icon'
							src='/images/veritas/invoice//WalletIcon.svg'
						/>
						<div className='ms-2'>
							<span> {props.payment.MetodoPagamento.Etichetta} </span>
							<br />
							<span className='app-bold'>
								{props.payment.MetodoPagamento.Valore}
							</span>
							{props.payment.MetodoPagamento.ValoreRimborso && (
								<div>{props.payment.MetodoPagamento.ValoreRimborso}</div>
							)}
						</div>
					</div>
				</div>
			) : (
				''
			)}

			<div className='card app-card-with-background p-5 d-none d-sm-block'>
				<h4>PAGAMENTO</h4>
				{props.payment.PagamentiRegolari && (
					<div className='app-info-div mt-4'>
						<img
							alt=''
							className='app-info-icon'
							src='/images/veritas/invoice//HappyFaceIcon.svg'
						/>
						<div className='ms-2'>
							<span> {props.payment.PagamentiRegolari.Etichetta} </span>
							<br />
							<span className='app-bold'>
								{props.payment.PagamentiRegolari.Valore}
							</span>
						</div>
					</div>
				)}
				{props.payment.PagamentiNonRegolari && (
					<div className='app-info-div mt-4'>
						<img
							alt=''
							className='app-info-icon'
							src='/images/veritas/invoice//SadFaceIcon.svg'
						/>
						<div className='ms-2'>
							<span> {props.payment.PagamentiNonRegolari.Etichetta} </span>
							<br />
							<span className='app-bold'>
								{props.payment.PagamentiNonRegolari.Valore}
							</span>
							<br />
							{props.payment.PagamentiNonRegolari.DettaglioNP.map(
								(item, index) => (
									<div key={index}>{item.Valore}</div>
								)
							)}
						</div>
					</div>
				)}
				<div className='app-info-div mt-4'>
					<img
						alt=''
						className='app-info-icon'
						src='/images/veritas/invoice//WalletIcon.svg'
					/>
					<div className='ms-2'>
						<span> {props.payment.MetodoPagamento.Etichetta} </span>
						<br />
						<span className='app-bold'>
							{props.payment.MetodoPagamento.Valore}
						</span>
						{props.payment.MetodoPagamento.ValoreRimborso && (
							<div>{props.payment.MetodoPagamento.ValoreRimborso}</div>
						)}
					</div>
				</div>
			</div>
		</>
	);
};

export default PaymentDetail;
