const Gone = () => {
	return (
		<div className='d-flex fullscreen-gradient mx-2 mx-md-0 flex-column justify'>
			<div className='mx-auto mt-5 text-center'>
				<img
					alt=''
					className='w-50'
					src='/images/veritas/invoice/VeritasLogo.svg'
				/>
			</div>
			<div className='card mx-auto mt-5'>
				<div className='card p-5 text-center app-bold'>
					Questa bolletta smart non è più disponibile perché il link non è più
					valido.
					<br />
					<br /> Per consultare tutte le tue bollette, accedi allo{' '}
					<a
						target='_blank'
						className='app-bold'
						href='https://serviziweb.gruppoveritas.it/'
						rel='noreferrer'
					>
						Sportello OnLine
					</a>
				</div>
			</div>
		</div>
	);
};

export default Gone;
