import React, { useEffect } from 'react';
import { useStore } from '../context/store';

type Props = {};

const UnhandledError = (props: Props) => {
	const { loaderStore } = useStore();

	useEffect(() => loaderStore.setAppLoadingFalse, [loaderStore]);

	return (
		<div id='not-found' className='app-container'>
			<div className='container'>
				<div className='row justify-content-center'>
					<div className='col-11 col-md-6 col-lg-5'>
						<div className='app-not-found text-center p-5'>
							<h1>
								<span className='text-warning'>Errore</span>
							</h1>
							<h2>Scusa</h2>
							<h5>si è verificato un errore inaspettato</h5>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default UnhandledError;
