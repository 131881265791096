import { requests } from './agent';
import { store } from '../context/store';

const Tracking = {
	track: (description: string, url?: string, logCustomer: boolean = false) => {
		let customer = null;

		if (logCustomer) {
			let customerString = localStorage.getItem('customer') || '';
			customer = JSON.parse(customerString);
		}

		return requests.post('api/activity/save', {
			//TODO: da provare
			Url_In: window.location.href,
			Url_Out: url ?? window.location.href,
			DocumentId: store.userStore.getDecodedToken()?.id,
			Description: description,
			CustomersInfo: customer,
		});
	},
};

export default Tracking;
