import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
	EtichettaValore,
	EtichettaValoreKey,
} from '../../../../../models/etichetta-valore';

type Props = {
	importoBolletta: EtichettaValore;
	scadenzaBolletta: EtichettaValore;
	dettaglioImportiMulti: EtichettaValoreKey[];
};

const DocumentSummary = (props: Props) => {
	const navigate = useNavigate();

	const goToDocument = async (key: string) => {
		navigate('invoice/' + key);
	};

	return (
		<div id='document-summary' className='card p-5'>
			<div className='app-riep-header'>
				<h4>RIEPILOGO DOCUMENTI</h4>
				<div className='app-riep-header-info'>
					<h2>{props.importoBolletta.Valore}€</h2>
					<p className='app-p-bigger'>{props.importoBolletta.Etichetta}</p>
				</div>
			</div>
			<hr />
			{props.dettaglioImportiMulti.map((item, index) => (
				<div className='mt-2' key={index}>
					<div className='app-riep-info'>
						<span>{item.Etichetta}</span>
						<span className='app-bold'>
							{item.Valore ? item.Valore + '€' : ''}
						</span>
					</div>
					<button
						className='btn app-btn-details mb-2 mt-2'
						onClick={() => goToDocument(item.Key)}
					>
						Vai al documento
					</button>
				</div>
			))}
			<hr />
			<div className='mt-2 app-riep-info app-riep-total'>
				<span>{props.scadenzaBolletta.Etichetta}</span>
				<span className='app-bold'>{props.scadenzaBolletta.Valore}</span>
			</div>
		</div>
	);
};

export default DocumentSummary;
