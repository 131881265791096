import { Vehicle } from './vehicle';

export class Document {
	public Id: number;
	public Json: Json;
	public Url: string;
}

export class Json {
	public StatusOptions: StatusOptions[];
	public Dealership: string;
	public CampaignNumber: string;
	public Holder: Owner;
	public Vehicles: Vehicle[];
}

interface StatusOptions {
	Key: number;
	Value: string;
	IsFormVisible: boolean;
}

export class Owner {
	public Address: string;
	public Cap: string;
	public City: string;
	public State: string;
	public PhoneNumber: string;
	public Email: string;
	public Pec: string;
	public WebSite: string;
	public Name: string;
	public Surname: string;
	public BusinessName: string;
	public Vat: string;
	public CF: string;
	public StreetNumber: string;
	public Province: string;
}
