import agent from '../../../../api/agent';
import { Static } from '../../../../static';
import { EtichettaValore } from '../../../../models/etichetta-valore';
import { DatiBolletta } from '../models/dati-bolletta';
import DownloadPdf from './DownloadPdf';

type Props = {
	isSingleDocument: boolean;
	importoTotaleContratto: EtichettaValore;
	scadenzaBolletta: EtichettaValore;
	documentoN: EtichettaValore;
	documentoPeriodo: EtichettaValore;
	datiBolletta: DatiBolletta;
	consumoFatturato?: EtichettaValore;
};

const DocumentDetail = (props: Props) => {
	const showDetails = async () => {
		agent.Tracking.track(
			Static.onShowDetails + ' ' + Static.fromDocumentDetailComponent
		);

		const el = document.getElementById('detail');
		if (el)
			el.scrollIntoView({
				behavior: 'smooth',
				block: 'center',
				inline: 'center',
			});
	};

	return (
		<div id='document-detail'>
			{props.isSingleDocument ? (
				<div className='card p-5'>
					<div className='mb-3'>
						<h2>{props.importoTotaleContratto.Valore} €</h2>
						<p className='app-p-bigger'>
							{props.importoTotaleContratto.Etichetta}
						</p>
					</div>
					<div className='mb-3'>
						<h2>{props.scadenzaBolletta.Valore}</h2>
						<p className='app-p-bigger'>{props.scadenzaBolletta.Etichetta}</p>
					</div>
					{props.consumoFatturato && (
						<div className='mb-3'>
							<h2>{props.consumoFatturato.Valore} mc</h2>
							<p className='app-p-bigger'>{props.consumoFatturato.Etichetta}</p>
						</div>
					)}
					<button className='btn app-btn-details mb-3' onClick={showDetails}>
						Altri dettagli
					</button>
					<hr />
					<div className='row mt-3'>
						<div className='col-6'>
							<div>
								{props.documentoN.Etichetta}
								<p className='app-bold'>{props.documentoN.Valore}</p>
							</div>
						</div>
						<div className='col-6'>
							<div>
								{props.documentoPeriodo.Etichetta}
								<p className='app-bold'>{props.documentoPeriodo.Valore}</p>
							</div>
						</div>
					</div>
					<DownloadPdf datiBolletta={props.datiBolletta} />
				</div>
			) : (
				<div className='p-5 app-detail-box'>
					<h1 className='mb-3 mb-md-5'>
						Dettaglio {props.documentoN.Etichetta} {props.documentoN.Valore}
					</h1>
					<div className='mt-3 mt-md-5'>
						{props.importoTotaleContratto.Etichetta}
						<p className='app-bold'>{props.importoTotaleContratto.Valore} €</p>
					</div>
					<div>
						{props.scadenzaBolletta.Etichetta}
						<p className='app-bold'>{props.scadenzaBolletta.Valore}</p>
					</div>
					{props.consumoFatturato && (
						<div>
							{props.consumoFatturato.Etichetta}
							<p className='app-bold'>{props.consumoFatturato.Valore} mc</p>
						</div>
					)}
					<div>
						{props.documentoPeriodo.Etichetta}
						<p className='app-bold'>{props.documentoPeriodo.Valore}</p>
					</div>
					<button className='btn app-btn-details mb-3' onClick={showDetails}>
						Altri dettagli
					</button>
				</div>
			)}
		</div>
	);
};

export default DocumentDetail;
