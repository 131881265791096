import { useState } from 'react';

import { EtichettaValore } from '../../../../models/etichetta-valore';
import agent from '../../../../api/agent';
import { Static } from '../../../../static';

type Props = {
	centroRaccolta: EtichettaValore;
};

const CollectionCenter = (props: Props) => {
	const [showDetails, setShowDetails] = useState(false);

	const viewOnGoogleMaps = async () => {
		agent.Tracking.track(
			Static.onViewOnGoogleMapsClick +
				' ' +
				Static.fromCollectionCenterComponent,
			Static.urlGoogleMaps
		);

		window.open(Static.urlGoogleMaps + props.centroRaccolta.Valore, '_blank');
	};

	const toggleDetails = async () => {
		agent.Tracking.track(
			Static.toggleDetail + ' ' + Static.fromCollectionCenterComponent
		);
		setShowDetails((prev) => !prev);
	};

	return (
		<>
			<div className='card app-card-with-background ps-5 pe-5 pt-3 pb-3 mb-3 d-block d-sm-none'>
				<div className='app-toggle-show' onClick={toggleDetails}>
					<h4 className='mb-0'>CENTRO DI RACCOLTA DI RIFERIMENTO</h4>
					<i
						className={showDetails ? 'fa fa-angle-up' : 'fa fa-angle-down'}
						style={{ fontSize: '1.5rem' }}
					></i>
				</div>
			</div>
			{showDetails && (
				<div className='card app-card-with-background p-5 d-block d-sm-none'>
					<div className='app-info-div'>
						<img
							alt=''
							className='app-info-icon'
							src='/images/veritas/invoice//MapsIcon.svg'
						/>
						<div className='ms-2'>
							<span> {props.centroRaccolta.Etichetta} </span>
							<br />
							<span className='app-bold'>{props.centroRaccolta.Valore}</span>
						</div>
					</div>
					<button
						className='btn app-btn-details mt-4'
						onClick={viewOnGoogleMaps}
					>
						<img
							alt=''
							className='app-icon-white-hover'
							src='/images/veritas/invoice//OnGoogleMapsIcon.svg'
						/>
						Visualizza su Google Maps
					</button>
				</div>
			)}
			<div className='card app-card-with-background p-5 d-none d-sm-block'>
				<h4>CENTRO DI RACCOLTA DI RIFERIMENTO</h4>
				<div className='app-info-div mt-4'>
					<img
						alt=''
						className='app-info-icon'
						src='/images/veritas/invoice//MapsIcon.svg'
					/>
					<div className='ms-2'>
						<span> {props.centroRaccolta.Etichetta} </span>
						<br />
						<span className='app-bold'>{props.centroRaccolta.Valore}</span>
					</div>
				</div>
				<button className='btn app-btn-details mt-4' onClick={viewOnGoogleMaps}>
					<img
						alt=''
						className='app-icon-white-hover'
						src='/images/veritas/invoice//OnGoogleMapsIcon.svg'
					/>
					Visualizza su Google Maps
				</button>
			</div>
		</>
	);
};

export default CollectionCenter;
