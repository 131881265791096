import { date, object } from 'yup';
import { Formik, FormikHelpers } from 'formik';

import { Json } from '../models/json';
import agent from '../../../../../api/agent';
import { toast } from 'react-toastify';
import { Navigation, VehicleStatus } from '../models/navigation.enum';
import { Static } from '../../../../../static';
import { JsonResponse, VehicleRestored } from '../models/jsonResponse';
import { DocumentData } from '../models/document-data';
import RestoredForm from './RestoredForm';
import { useStore } from '../../../../../context/store';

type Props = {
	json: Json;
	documentId: number;
	selectedFrame: string;
	changeView: (n: number) => void;
};

const validationSchema = object({
	restoredDate: date().required('La data di risanamento è obbligatoria'),
});

const Restored = (props: Props) => {
	const { loaderStore } = useStore();
	const initialValues = {
		restoredDate: '',
	};

	const submit = async (values: any, actions: FormikHelpers<any>) => {
		let documentData = mapJsonObject(values);
		loaderStore.setAppLoadingTrue();
		agent.Document.saveDocumentData(documentData)
			.then((res) => {
				if (res === true) {
					toast.success(Static.thanks);
					props.changeView(Navigation.mainView);
				} else toast.error(Static.saveFailed);
				actions.setSubmitting(false);
				loaderStore.setAppLoadingFalse();
			})
			.catch((ex) => {
				actions.setSubmitting(false);
				loaderStore.setAppLoadingFalse();
				toast.error(Static.anUnexpectedErrorHasOccurred);
			});
	};

	const mapJsonObject = (formData: any) => {
		let jsonRestoredResponse = new JsonResponse();
		jsonRestoredResponse.CampaignDate = props.json.CampaignDate;
		jsonRestoredResponse.HolderName = props.json.HolderName;
		jsonRestoredResponse.HolderSurname = props.json.HolderSurname;
		jsonRestoredResponse.HolderBusinessName = props.json.HolderBusinessName;
		jsonRestoredResponse.HolderVat = props.json.HolderVAT;
		jsonRestoredResponse.HolderCF = props.json.HolderCF;
		jsonRestoredResponse.IvecoContactEmail = props.json.IvecoContactEmail;
		jsonRestoredResponse.IvecoContactPhoneNumber =
			props.json.IvecoContactNumber;
		jsonRestoredResponse.Language = props.json.LanguageFlag;
		jsonRestoredResponse.FrameNumber = props.selectedFrame;
		jsonRestoredResponse.CampaignId = props.json.CampaignId;
		jsonRestoredResponse.VehicleStatus = VehicleStatus[VehicleStatus.Restored];
		jsonRestoredResponse.VehicleStatusId = VehicleStatus.Restored.toString();
		jsonRestoredResponse.CampaignDescription = props.json.CampaignDescription;
		jsonRestoredResponse.HolderAddress = props.json.HolderAddress;
		jsonRestoredResponse.HolderCap = props.json.HolderCap;
		jsonRestoredResponse.HolderCity = props.json.HolderCity;
		jsonRestoredResponse.HolderState = props.json.HolderState;
		jsonRestoredResponse.HolderEmail = props.json.HolderEmail;
		jsonRestoredResponse.HolderPec = props.json.HolderPec;
		jsonRestoredResponse.HolderWebSite = props.json.HolderWebSite;
		jsonRestoredResponse.HolderPhoneNumber = props.json.HolderPhoneNumber;

		let jsonRestored = new VehicleRestored();
		jsonRestored.RestoredDate = formData.restoredDate;

		jsonRestoredResponse.VehicleRestoredDetails = jsonRestored;

		let documentData = new DocumentData();
		documentData.documentId = props.documentId;
		documentData.json = JSON.stringify(jsonRestoredResponse);

		return documentData;
	};

	const goBack = () => {
		props.changeView(Navigation.mainView);
	};

	return (
		<div id='stolenForm' className='formGroup mt-5'>
			<div className='row menuTitle g-0'>Veicolo risanato in data:</div>
			<Formik
				validateOnMount
				enableReinitialize
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={submit}
			>
				<RestoredForm goBack={goBack} />
			</Formik>
			<div className='menuText'>
				Le chiediamo gentilmente di inviarci copia della documentazione di
				assistenza all’indirizzo mail: PIP_Iveco_Italia@pec.cnhind.com o
				direttamente via fax al numero 011 2740972.
			</div>
		</div>
	);
};

export default Restored;
