export class Credential {
	public login: string;
	public loginProfileId: number = 2;
	public password: string | null;

	constructor(login: string, password: any, loginProfileId: number = 2) {
		this.login = login;
		if (password != null) this.password = JSON.stringify(password);
		this.loginProfileId = loginProfileId;
	}
}
