import axios, { AxiosResponse } from 'axios';

import { store } from '../context/store';
import Auth from './Auth';
import Document from './Document';
import Tracking from './Tracking';
axios.defaults.baseURL = process.env.REACT_APP_API_URL;

axios.interceptors.request.use((config) => {
	const token = store.userStore.token;

	if (token) config.headers.Authorization = `Bearer ${token}`;
	return config;
});

export const responseBody = <T>(response: AxiosResponse<T>) => response.data;

export const requests = {
	get: <T>(url: string) => axios.get<T>(url).then(responseBody),
	post: <T>(url: string, body: {}) =>
		axios.post<T>(url, body).then(responseBody),
	put: <T>(url: string, body: {}) => axios.put<T>(url, body).then(responseBody),
	patch: <T>(url: string, body: {}) =>
		axios.patch<T>(url, body).then(responseBody),
	del: <T>(url: string) => axios.delete<T>(url).then(responseBody),
};

const agent = {
	Auth,
	Document,
	Tracking,
};

export default agent;
