import { Owner } from './json';

export class Vehicle {
	public Guuid: string;
	public Chassis: string;
	public LicensePlate: string;
	public Status: Status;
	public NewOwner: Owner | null;
}

export class Status {
	Key: number | undefined;
	Value: string | undefined;
}
