import { useState } from 'react';
import { toast } from 'react-toastify';
import { Spinner } from 'react-bootstrap';

import { DatiBolletta } from '../models/dati-bolletta';
import agent from '../../../../api/agent';
import { Static } from '../../../../static';

type Props = { datiBolletta: DatiBolletta; className?: string };

function DownloadPdf(props: Props) {
	const [loading, setLoading] = useState(false);

	const downloadPdf = async () => {
		agent.Tracking.track(Static.onDownloadInvoiceClick);
		setLoading(true);

		agent.Document.getEncodedUrl(props.datiBolletta)
			.then((url) => {
				setLoading(false);

				if (!window.open(url, '_blank')) window.location.href = url;
			})
			.catch(() => {
				setLoading(false);
				toast.error(Static.anUnexpectedErrorHasOccurred);
			});
	};

	return (
		<button
			id='download-pdf'
			className={`btn app-btn-download ${props.className || ''}`}
			onClick={downloadPdf}
		>
			{loading ? (
				<Spinner
					as='span'
					animation='border'
					size='sm'
					role='status'
					aria-hidden='true'
				/>
			) : (
				<>
					<img
						alt=''
						className='app-icon-download'
						src='/images/veritas/invoice/DownloadIcon.svg'
					/>
					Scarica la bolletta
				</>
			)}
		</button>
	);
}

export default DownloadPdf;
