import { useLoaderData, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import React, { useState } from 'react';
import { Formik, FormikHelpers } from 'formik';
import { object, string } from 'yup';

import { Json, Owner } from '../models/json';
import { Status, Vehicle } from '../models/vehicle';
import MainForm from './MainForm';
import { DocumentData } from '../../../iveco/recall-campaign/vehicle/models/document-data';
import agent from '../../../../api/agent';
import { Static } from '../../../../static';
import { useStore } from '../../../../context/store';

type Props = {};

const validationSchema = object().shape({
	surname: string().required('Il cognome è obbligatorio'),
	name: string().required('Il nome è obbligatorio'),
	address: string().required("L'indirizzo è obbligatorio"),
	cap: string().required('Il CAP è obbligatorio'),
	city: string().required('La città è obbligatoria'),
	streetNumber: string().required('Il numero civico è obbligatorio'),
	province: string().required('La provincia è obbligatoria'),
});

const Main = (props: Props) => {
	const baseJson = useLoaderData() as Json;
	const [json, setJson] = useState<Json>(JSON.parse(JSON.stringify(baseJson)));
	const [index, setIndex] = useState<number>();
	const [savedIndexes, setSavedIndexes] = useState<number[]>([]);
	const vehicle = index !== undefined ? json?.Vehicles[index] : null;

	const { loaderStore, modalStore } = useStore();
	const { id } = useParams();
	const chassisPlates = json.Vehicles.map((x) => {
		return {
			chassis: x.Chassis,
			license_plate: x.LicensePlate,
		};
	});

	const initialValues = {
		surname: vehicle?.NewOwner?.Surname || '',
		name: vehicle?.NewOwner?.Name || '',
		address: vehicle?.NewOwner?.Address || '',
		cap: vehicle?.NewOwner?.Cap || '',
		city: vehicle?.NewOwner?.City || '',
		streetNumber: vehicle?.NewOwner?.StreetNumber || '',
		province: vehicle?.NewOwner?.Province || '',
	};

	const resetUnsavedVehicle = () => {
		if (index !== undefined && index !== -1 && !savedIndexes.includes(index)) {
			const veh = JSON.parse(JSON.stringify([...json.Vehicles]));
			veh[index] = JSON.parse(JSON.stringify(baseJson.Vehicles[index]));
			setJson((prev) => {
				return {
					...prev,
					Vehicles: veh,
				};
			});
		}
	};

	const chassisSelectOnChange = (
		event: React.ChangeEvent<HTMLSelectElement>
	) => {
		resetUnsavedVehicle();
		const value = event.target.value;
		const newIndex = json.Vehicles.findIndex((x) => x.Chassis === value);
		setIndex(newIndex);
	};

	const plateSelectOnChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
		resetUnsavedVehicle();
		const value = event.target.value;
		const newIndex = json.Vehicles.findIndex((x) => x.LicensePlate === value);
		setIndex(newIndex);
	};

	const submit = async (values: any, actions: FormikHelpers<any> | null) => {
		const index = json.Vehicles.findIndex((v) => v.Guuid === vehicle?.Guuid);
		const vehicles = [...json?.Vehicles];
		vehicles[index].Status.Key = vehicle?.Status.Key!;
		vehicles[index].Status.Value = vehicle?.Status.Value!;

		if (isFormVisible(vehicle?.Status.Key!)) {
			const newOwner = new Owner();
			newOwner.Surname = values.surname;
			newOwner.Name = values.name;
			newOwner.Address = values.address;
			newOwner.Cap = values.cap;
			newOwner.City = values.city;
			newOwner.StreetNumber = values.streetNumber;
			newOwner.Province = values.province;

			vehicles[index!].NewOwner = newOwner;
		} else {
			vehicles[index].NewOwner = null;
		}

		setJson((old) => {
			return { ...old, Vehicles: vehicles };
		});

		let documentData = new DocumentData();
		documentData.json = JSON.stringify(json);
		documentData.documentId = Number(id);
		loaderStore.setAppLoadingTrue();
		agent.Document.saveDocumentData(documentData)
			.then((res) => {
				if (res === true) {
					toast.success(Static.thanks);
				} else toast.error(Static.saveFailed);

				actions?.setSubmitting(false);
				loaderStore.setAppLoadingFalse();

				setSavedIndexes((prev) => {
					return [...prev, index];
				});
			})
			.catch((ex) => {
				actions?.setSubmitting(false);
				loaderStore.setAppLoadingFalse();
				toast.error(Static.anUnexpectedErrorHasOccurred);
			});
	};

	const statusChange = (key: string) => {
		const v = { ...vehicle } as Vehicle;
		const intKey = Number(key);

		if (intKey) {
			v.Status = new Status();
			v.Status.Key = intKey;
			v.Status.Value = json.StatusOptions.find((x) => x.Key === intKey)?.Value!;
		} else {
			v.Status = new Status();
			v.Status.Key = undefined;
			v.Status.Value = undefined;
		}
		const vehicles = [...json?.Vehicles];
		vehicles[index!] = v;
		setJson((old) => {
			return { ...old, Vehicles: vehicles };
		});
	};

	const privacyClick = () => {
		modalStore.openModal(
			<div className='p-3'>
				<div className='text-end'>
					<i
						className='fa-solid fa-x pointer'
						onClick={modalStore.closeModal}
					></i>
				</div>
				<br />
				<p dangerouslySetInnerHTML={{ __html: Static.psaPrivacy }}></p>
			</div>,
			'dark-bg-color rounded text-white'
		);
	};

	const isFormVisible = (key: number | undefined) => {
		if (!key) return false;
		return (
			json.StatusOptions.find((x) => x.Key === key)?.IsFormVisible || false
		);
	};

	return (
		<div className='d-flex flex-column' style={{ minHeight: 'inherit' }}>
			<div className='bg-black ps-5 pt-5 flex-shrink-0'>
				<div className='text-md-start text-center'>
					<img src='/images/psa/logo-psa.svg' alt='' />
				</div>
				<div className='justify-content-between d-md-flex'>
					<div>
						<h1 className='text-white mt-3 display-4 fw-bold'>Buongiorno</h1>
						<h1 className='text-white fw-bold display-1'>{json.Dealership}</h1>
					</div>
					<div className='text-end'>
						<img
							className='image-responsive'
							src='/images/psa/psa-cars.png'
							alt=''
						/>
					</div>
				</div>
			</div>
			<div id='main-document' className='main-gradient flex-grow-1'>
				<div className='text-white h3 fw-bold py-md-4 py-3'>
					VARIAZIONI DI POSSESSO
				</div>
				<div className='h4 text-white'>CAMPAGNA N°: {json.CampaignNumber}</div>
				<div className='row mt-md-4 mt-3'>
					<div className='col-md-6'>
						{' '}
						<select
							className='form-select p-3 fw-bold mt-3'
							onChange={(e) => chassisSelectOnChange(e)}
							value={vehicle?.Chassis}
						>
							<option>Seleziona il telaio</option>
							{chassisPlates.map((x, index) => {
								return (
									<option key={index} value={x.chassis}>
										{x.chassis}
									</option>
								);
							})}
						</select>
					</div>
					<div className='col-md-6'>
						<select
							className='form-select p-3 fw-bold mt-3'
							onChange={(e) => plateSelectOnChange(e)}
							value={vehicle?.LicensePlate}
						>
							<option>Seleziona la targa</option>
							{chassisPlates.map((x, index) => {
								return (
									<option key={index} value={x.license_plate}>
										{x.license_plate}
									</option>
								);
							})}
						</select>
					</div>
				</div>
				{vehicle && (
					<div className='row my-4'>
						<div className='col-md-3 text-white text-lg mt-3'>
							Il Veicolo è stato
						</div>
						<div className='col-md-9'>
							<select
								className='form-select p-3 fw-bold mt-3'
								onChange={(e) => statusChange(e.target.value)}
								value={vehicle?.Status?.Key || ''}
							>
								<option>Seleziona un'opzione</option>
								{json.StatusOptions.map((op, index) => (
									<option key={op.Key} value={op.Key}>
										{op.Value}
									</option>
								))}
							</select>
						</div>
					</div>
				)}
				{vehicle && isFormVisible(vehicle?.Status?.Key) && (
					<Formik
						validateOnChange
						enableReinitialize
						initialValues={initialValues}
						validationSchema={validationSchema}
						onSubmit={submit}
					>
						<MainForm privacyClick={privacyClick} />
					</Formik>
				)}
				{vehicle && !isFormVisible(vehicle?.Status?.Key) && (
					<>
						<i className='text-white' style={{ fontSize: '0.8rem' }}>
							Cliccando sul pulsante "Conferma" dichiaro di aver letto e
							accettato le condizioni sul trattamento dei dati. Per maggiori
							informazioni clicca su{' '}
							<i
								className='text-decoration-underline pointer'
								onClick={privacyClick}
							>
								INFORMATIVA PRIVACY
							</i>
						</i>
						<div className='col-md-2 d-flex mt-4'>
							<button
								className='btn flex-grow-1'
								type='button'
								disabled={!vehicle?.Status?.Key && !vehicle?.Status?.Value}
								onClick={() => submit(null, null)}
							>
								Conferma
							</button>
						</div>
					</>
				)}
			</div>
		</div>
	);
};

export default Main;
