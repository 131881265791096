import { useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import { useStore } from '../../../../context/store';
import { Credential } from '../../../../models/credential';
import agent from '../../../../api/agent';
import { Static } from '../../../../static';

type Props = {};

const Login = (props: Props) => {
	const { userStore, loaderStore } = useStore();
	const { id } = useParams<{ id: string }>();

	const navigate = useNavigate();
	const login = useCallback(
		async (id: string) => {
			loaderStore.setAppLoadingTrue();
			userStore
				.authenticate(new Credential(id, null))
				.then(() => {
					const decodedToken = userStore.getDecodedToken();
					agent.Tracking.track('login PSA', decodedToken?.url);
					loaderStore.setAppLoadingFalse();
					navigate(decodedToken?.url + '/' + decodedToken?.id);
				})
				.catch((error) => {
					if (error.response.status === 410) {
						toast.error(Static.theDocumentIsNoLongerAvailable);
					} else if (error.response.status === 401) {
						toast.error(Static.incorrectAuthenticationData);
					} else {
						toast.error(Static.anUnexpectedErrorHasOccurred);
					}

					navigate('/not-found');
					loaderStore.setAppLoadingFalse();
				});
		},
		[userStore, loaderStore, navigate]
	);

	useEffect(() => {
		if (!id) return navigate('/not-found');
		login(id!);
	}, [login, id, navigate]);

	return <></>;
};

export default Login;
