import { makeAutoObservable } from 'mobx';

interface Modal {
	open: boolean;
	classes: string;
	size: 'sm' | 'lg' | 'xl' | undefined;
	body: JSX.Element | null;
}

export default class ModalStore {
	modal: Modal = {
		open: false,
		size: 'lg',
		body: null,
		classes: '',
	};

	constructor() {
		makeAutoObservable(this);
	}

	openModal = (
		content: JSX.Element,
		classes?: string,
		size?: 'sm' | 'lg' | 'xl' | undefined
	) => {
		this.modal.classes = classes || '';
		this.modal.open = true;
		this.modal.body = content;
		if (!!size) this.modal.size = size;
	};

	closeModal = () => {
		this.modal.open = false;
		this.modal.body = null;
		this.modal.size = 'lg';
		this.modal.classes = '';
	};
}
