import { useEffect } from 'react';
import { useStore } from '../context/store';

export const NotFound = () => {
	const { loaderStore } = useStore();

	useEffect(() => loaderStore.setAppLoadingFalse, [loaderStore]);

	return (
		<div id='not-found' className='app-container'>
			<div className='container'>
				<div className='row justify-content-center'>
					<div className='col-11 col-md-6 col-lg-5'>
						<div className='app-not-found text-center p-5'>
							<h1>
								4<span className='light-blue'>0</span>4
							</h1>
							<h2>Scusa</h2>
							<h5>non riusciamo a trovare la pagina che stai cercando</h5>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
