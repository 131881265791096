export class JsonResponse {
	public CampaignDate: string;
	public CampaignId: string;
	public CampaignDescription: string;
	public HolderAddress: string;
	public HolderCap: string;
	public HolderCity: string;
	public HolderState: string;
	public HolderPhoneNumber: string;
	public HolderEmail: string;
	public HolderPec: string;
	public HolderWebSite: string;
	public HolderName: string;
	public HolderSurname: string;
	public HolderBusinessName: string;
	public HolderVat: string;
	public HolderCF: string;
	public IvecoContactEmail: string;
	public IvecoContactPhoneNumber: string;
	public Language: string;
	public FrameNumber: string;
	public VehicleStatus: string;
	public VehicleStatusId: string;
	public VheicleSoldDetails: VehicleSold;
	public VehicleUsedByThirdPartDetails: VehicleUsedByThirdPart;
	public VehicleStolenDetails: VehicleStolen;
	public VehicleDemolishedDetails: VehicleDemolished;
	public VehicleRestoredDetails: VehicleRestored;
	public RegistryUpdateDetails: RegistryUpdate;
	constructor() {}
}

export class VehicleSold {
	public NewHolderSurname: string;
	public NewHolderName: string;
	public NewHolderAddress: string;
	public NewHolderCAP: string;
	public NewHolderCity: string;
	public NewHolderState: string;
	public NewHolderBusinessName: string;
	public NewHolderEmail: string;
	public NewHolderPhoneNumber: string;
	constructor() {}
}

export class VehicleUsedByThirdPart {
	public UtilizerSurname: string;
	public UtilizerName: string;
	public UtilizerAddress: string;
	public UtilizerCAP: string;
	public UtilizerCity: string;
	public UtilizerState: string;
	public UtilizerBusinessName: string;
	public UtilizerEmail: string;
	public UtilizerPhoneNumber: string;
	constructor() {}
}

export class VehicleStolen {
	public StolenDate: Date;
	constructor() {}
}

export class VehicleDemolished {
	public DemolishedDate: Date;
	constructor() {}
}

export class VehicleRestored {
	public RestoredDate: Date;
	constructor() {}
}

export class RegistryUpdate {
	public UpdatedAddress: string;
	public UpdatedCap: string;
	public UpdatedCity: string;
	public UpdatedState: string;
	public UpdatedPhoneNumber: string;
	public UpdatedEmail: string;
	public UpdatedPec: string;
	public UpdatedWebSite: string;
	public UpdatedName: string;
	public UpdatedSurname: string;
	public UpdatedBusinessName: string;
	public UpdatedVat: string;
	public UpdatedCF: string;
	public UpdatedFramesNumber: string;
	constructor() {}
}
