import { useState } from 'react';
import { AddToCalendarButton } from 'add-to-calendar-button-react';

import agent from '../../../../api/agent';
import { Static } from '../../../../static';
import { EtichettaValore } from '../../../../models/etichetta-valore';
import utils from '../../../../common/utils';
import { PeriodoLettura } from '../models/periodo-lettura';
type Props = {
	matricolaContatore: EtichettaValore;
	ultimaLettura: EtichettaValore;
	documentoN: EtichettaValore;
	periodoLettura: PeriodoLettura;
};

const MeterDetail = (props: Props) => {
	const [showDetails, setShowDetails] = useState(false);
	const periodoLetturaStart = new Date(props.periodoLettura?.start);
	const periodoLetturaEnd = new Date(props.periodoLettura?.end);
	const validPeriodoLettura =
		utils.isValidDate(periodoLetturaStart) &&
		utils.isValidDate(periodoLetturaEnd);
	const onSOLClick = async () => {
		agent.Tracking.track(
			`${Static.onSOLClick} ${Static.fromMeterDetailComponent}`,
			Static.urlVeritasSOL
		);

		window.open(Static.urlVeritasSOL, '_blank');
	};

	const toggleDetails = async () => {
		agent.Tracking.track(
			`${Static.toggleDetail} ${Static.fromMeterDetailComponent}`
		);

		setShowDetails((prev) => !prev);
	};

	return (
		<>
			<div className='card app-card-with-background ps-5 pe-5 pt-3 pb-3 mb-3 d-block d-sm-none'>
				<div className='app-toggle-show' onClick={toggleDetails}>
					<h4 className='mb-0'>INFO LETTURA E CONTATORE</h4>
					<i
						className={showDetails ? 'fa fa-angle-up' : 'fa fa-angle-down'}
						style={{ fontSize: '1.5rem' }}
					></i>
				</div>
			</div>

			{showDetails ? (
				<div className='card app-card-with-background p-5 d-block d-sm-none mb-4'>
					<div className='app-info-div'>
						<img
							alt=''
							className='app-info-icon'
							src='/images/veritas/invoice//CounterIcon.svg'
						/>
						<div className='ms-2'>
							<span> {props.matricolaContatore.Etichetta} </span>
							<br />
							<span className='app-bold'>
								{props.matricolaContatore.Valore}
							</span>
						</div>
					</div>
					<div className='app-info-div mt-4'>
						<img
							alt=''
							className='app-info-icon'
							src='/images/veritas/invoice//LastReadingIcon.svg'
						/>
						<div className='ms-2'>
							<span> {props.ultimaLettura.Etichetta} </span>
							<br />
							<span className='app-bold'>{props.ultimaLettura.Valore} mc</span>
						</div>
					</div>
					{props.periodoLettura && (
						<div className='app-info-div mt-4'>
							<img
								alt=''
								className='app-info-icon'
								src='/images/veritas/invoice//CalendarIcon.svg'
							/>
							<div className='ms-2'>
								<span> {props.periodoLettura?.Etichetta} </span>
								<br />
								<span className='app-bold'>{props.periodoLettura?.Valore}</span>
							</div>
						</div>
					)}
					<div className='app-info-div my-4'>
						<img
							alt=''
							className='app-info-icon'
							src='/images/veritas/invoice//WorldIcon.svg'
						/>
						<div className='ms-2'>
							<span>
								Puoi comunicare la lettura accedendo sullo{' '}
								<span className='app-link' onClick={onSOLClick}>
									Sportello Online Veritas
								</span>
							</span>
						</div>
					</div>
					{/* <button className='btn app-btn-details mt-4' onClick={rememberMe}>
						<img
							alt=''
							className='app-icon-white-hover'
							src='/images/veritas/invoice//RememberMeIcon.svg'
						/>
						Ricordamelo (file .ICS)
					</button> */}
					{validPeriodoLettura && (
						<AddToCalendarButton
							language='it'
							customCss='/atcb.css'
							buttonStyle='custom'
							label='Ricordamelo'
							name={
								'Effettua autolettura dell’acqua contatore matricola ' +
								props.matricolaContatore.Valore
							}
							description='Effettua l’autolettura del tuo contatore idrico, accedi sullo Sportello OnLine'
							options={[
								'Apple',
								'Google',
								'Microsoft365',
								'MicrosoftTeams',
								'Outlook.com',
								'Yahoo',
							]}
							location='World Wide Web'
							startDate={utils.formatDateYearMonthDay(periodoLetturaStart)}
							endDate={utils.formatDateYearMonthDay(periodoLetturaEnd)}
							timeZone='Europe/Rome'
						></AddToCalendarButton>
					)}
				</div>
			) : (
				''
			)}

			<div className='card app-card-with-background p-5 d-none d-sm-block'>
				<h4>INFO LETTURA E CONTATORE</h4>
				<div className='app-info-div mt-4'>
					<img
						alt=''
						className='app-info-icon'
						src='/images/veritas/invoice//CounterIcon.svg'
					/>
					<div className='ms-2'>
						<span> {props.matricolaContatore.Etichetta} </span>
						<br />
						<span className='app-bold'>{props.matricolaContatore.Valore}</span>
					</div>
				</div>
				<div className='app-info-div mt-4'>
					<img
						alt=''
						className='app-info-icon'
						src='/images/veritas/invoice//LastReadingIcon.svg'
					/>
					<div className='ms-2'>
						<span> {props.ultimaLettura.Etichetta} </span>
						<br />
						<span className='app-bold'>{props.ultimaLettura.Valore} mc</span>
					</div>
				</div>
				{props.periodoLettura && (
					<div className='app-info-div mt-4'>
						<img
							alt=''
							className='app-info-icon'
							src='/images/veritas/invoice//CalendarIcon.svg'
						/>
						<div className='ms-2'>
							<span> {props.periodoLettura?.Etichetta} </span>
							<br />
							<span className='app-bold'>{props.periodoLettura?.Valore}</span>
						</div>
					</div>
				)}
				<div className='app-info-div my-4'>
					<img
						alt=''
						className='app-info-icon'
						src='/images/veritas/invoice//WorldIcon.svg'
					/>
					<div className='ms-2'>
						<span>
							Puoi comunicare la lettura accedendo sullo{' '}
							<span className='app-link' onClick={onSOLClick}>
								Sportello Online Veritas
							</span>
						</span>
					</div>
				</div>
				{/* <button className='btn app-btn-details mt-4' onClick={rememberMe}>
					<img
						alt=''
						className='app-icon-white-hover'
						src='/images/veritas/invoice//RememberMeIcon.svg'
					/>
					Ricordamelo (file .ICS)
				</button> */}
				{validPeriodoLettura && (
					<AddToCalendarButton
						language='it'
						customCss='/atcb.css'
						buttonStyle='custom'
						label='Ricordamelo'
						name={
							'Effettua autolettura dell’acqua contatore matricola ' +
							props.matricolaContatore.Valore
						}
						description='Effettua l’autolettura del tuo contatore idrico, accedi sullo Sportello OnLine'
						options={[
							'Apple',
							'Google',
							'Microsoft365',
							'MicrosoftTeams',
							'Outlook.com',
							'Yahoo',
						]}
						location='World Wide Web'
						startDate={utils.formatDateYearMonthDay(periodoLetturaStart)}
						endDate={utils.formatDateYearMonthDay(periodoLetturaEnd)}
						timeZone='Europe/Rome'
					></AddToCalendarButton>
				)}
			</div>
		</>
	);
};

export default MeterDetail;
