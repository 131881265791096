import { Navigate, useRouteLoaderData } from 'react-router-dom';

import { JsonVeritasIdrico } from '../models/jsonVeritas';
import Welcome from '../../components/Welcome';
import DocumentSummary from '../../components/DocumentSummary';
import ReferenceDesk from '../../components/ReferenceDesk';
import PaymentDetail from '../../components/PaymentDetail';

const DocumentList = (props: { loader: string }) => {
	const json = useRouteLoaderData(props.loader) as JsonVeritasIdrico;
	if (!json) return <Navigate to='/veritas/not-found' />;

	return (
		<div id='document-list'>
			{/* <ngx-spinner bdColor="rgba(51,51,51,0.4)"></ngx-spinner> */}
			<div className='app-document-list-container'>
				<div className='row pt-4 pb-4 app-box-one-idrico'>
					<div className='col-12 col-md-6'>
						<div className='m-2 me-md-1 mt-md-5 ms-md-5 mb-md-5'>
							<Welcome
								welcome={json.Customer.Welcome}
								cliente={json.Customer.Cliente}
								codiceFiscale={json.Customer.CodiceFiscale}
								codiceUtente={json.Customer.CodiceUtente}
							/>
						</div>
					</div>
					<div className='col-12 col-md-6'>
						<div className='m-2 ms-md-1 mt-md-5 me-md-5 mb-md-5'>
							<DocumentSummary
								importoBolletta={json.Documento.ImportoBolletta}
								scadenzaBolletta={json.Documento.ScadenzaBolletta}
								dettaglioImportiMulti={json.DettaglioImportiMulti}
							/>
						</div>
					</div>
				</div>
				<div className='row pt-4'>
					<div className='col-12 col-md-6'>
						<div className='m-2 me-md-1 mt-md-5 ms-md-5 mb-md-5'>
							<ReferenceDesk sportello={json.Fatture[0].Sportello} />
						</div>
					</div>
					<div className='col-12 col-md-6'>
						<div className='m-2 ms-md-1 mt-md-5 me-md-5 mb-md-5'>
							<PaymentDetail payment={json.Pagamenti} />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default DocumentList;
