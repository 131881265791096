import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';

import agent from '../../../../../api/agent';
import { useStore } from '../../../../../context/store';
import { Static } from '../../../../../static';
import { Credential } from '../../../../../models/credential';
import { toast } from 'react-toastify';

type Props = {};

const Login = (props: Props) => {
	const navigate = useNavigate();
	const { id, type } = useParams();

	const [password, setPassword] = useState('');

	const { userStore, loaderStore } = useStore();

	useEffect(() => {
		userStore.logout();
	}, [userStore]);

	const onProcessingPersonalDataClick = () => {
		window.open(Static.urlDemoProcessingPersonalData, '_blank');
	};

	const onSubmit = () => {
		loaderStore.setAppLoadingTrue();

		var credentialArr: any = {};
		credentialArr[type!] = '' + password;

		userStore
			.authenticate(new Credential(id!, credentialArr))
			.then(() => {
				loaderStore.setAppLoadingFalse();
				var decodedToken = userStore.getDecodedToken();
				if (!decodedToken) return navigate('/not-found');
				agent.Tracking.track(Static.loginType + ' ' + type, decodedToken.url);
				navigate(decodedToken.url! + '/' + decodedToken.id!);
			})
			.catch((error) => {
				loaderStore.setAppLoadingFalse();
				if (error.response.status === 410)
					toast.error(Static.theDocumentIsNoLongerAvailable);
				else if (error.response.status === 401)
					toast.error(Static.incorrectAuthenticationData);
				else toast.error(Static.anUnexpectedErrorHasOccurred);
			});
	};

	return (
		<div id='login' className='app-container'>
			<div className='container'>
				{type === 'email' && (
					<div
						className='row justify-content-center'
						style={{ textAlign: 'center' }}
					>
						<div className='col-11 col-md-6 col-lg-5'>
							<div className='app-login-form p-5'>
								<img
									className='app-max-width-logo mb-0'
									alt='Logo'
									src='/images/demo/invoice/LogoPRT.png'
								/>

								<h4 className='text-center mt-4'>
									Inserisci le prime tre lettere della tua e-mail
								</h4>

								<div className='form-group ms-md-5 me-md-5 m-0 mt-4'>
									<div className='input-container'>
										<i className='fa fa-envelope icon'></i>
										<input
											type='password'
											className='form-control borderWhite input-field'
											required
											name='password'
											value={password}
											onChange={(e) => setPassword(e.target.value)}
											placeholder='Prime 3 lettere Email'
										/>
									</div>
								</div>

								<div className='text-center'>
									<button
										disabled={!password || password.length < 3}
										onClick={onSubmit}
										className='btn app-btn-success mt-1'
										type='button'
									>
										Conferma
									</button>
								</div>

								<p className='mt-4'>
									Per ogni ulteriore informazione sul trattamento dei tuoi dati
									personali puoi leggere l'informativa cliccando{' '}
									<span
										className='app-bold green'
										style={{ cursor: 'pointer' }}
										onClick={onProcessingPersonalDataClick}
									>
										qui
									</span>
								</p>
							</div>
						</div>
					</div>
				)}
				{type === 'sms' && (
					<div
						className='row justify-content-center'
						style={{ textAlign: 'center' }}
					>
						<div className='col-11 col-md-6 col-lg-5'>
							<div className='app-login-form p-5'>
								<img
									className='app-max-width-logo mb-0'
									alt='Logo'
									src='/images/demo/invoice/LogoPRT.png'
								/>

								<h4 className='text-center mt-4'>
									Inserisci le ultime tre cifre del tuo numero di cellulare
								</h4>
								<div className='form-group ms-md-5 me-md-5 m-0 mt-4'>
									<div className='input-container'>
										<i className='fas fa-phone-alt icon'></i>
										<input
											type='password'
											className='form-control borderWhite input-field'
											required
											name='password'
											value={password}
											onChange={(e) => setPassword(e.target.value)}
											placeholder='Ultimi 3 numeri Cell'
										/>
									</div>
								</div>
								<div className='text-center'>
									<button
										disabled={!password || password.length < 3}
										onClick={onSubmit}
										className='btn app-btn-success mt-1'
										type='submit'
									>
										Conferma
									</button>
								</div>

								<p className='mt-4'>
									Per ogni ulteriore informazione sul trattamento dei tuoi dati
									personali puoi leggere l'informativa cliccando
									<span
										className='app-bold green'
										style={{ cursor: 'pointer' }}
										onClick={onProcessingPersonalDataClick}
									>
										qui
									</span>
								</p>
							</div>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default Login;
