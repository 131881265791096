interface Props {
	title: string;
	message: string;
	btnOkText: string;
	btnCancelText: string;
	titleStyle: string;
	btnStyle: string;
	handleConfirm: () => void;
	handleDecline: () => void;
}

export const ConfirmContainer = ({
	title,
	message,
	btnOkText,
	btnCancelText,
	handleConfirm,
	handleDecline,
	titleStyle,
	btnStyle,
}: Props) => {
	return (
		<>
			<div className='pb-0 modal-header text-center'>
				<div>
					<h3 className={`modal-title text-center ${titleStyle}`}>{title}</h3>
				</div>
			</div>
			<div className='modal-body text-center ps-4 pe-4'>
				<p className='mb-0'>{message}</p>
			</div>
			<div className='modal-footer pt-0'>
				<button className={`btn ${btnStyle}`} onClick={handleConfirm}>
					{btnOkText}
				</button>
				<button className={`btn ${btnStyle}`} onClick={handleDecline}>
					{btnCancelText}
				</button>
			</div>
		</>
	);
};
