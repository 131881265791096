import { useEffect } from 'react';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {
	Navigate,
	useNavigate,
	useParams,
	useRouteLoaderData,
} from 'react-router';
import Chart from 'chart.js/auto';

import { Json } from '../models/json';
import { useStore } from '../../../../../context/store';
import Video from './modal/Video';
import agent from '../../../../../api/agent';
import { Static } from '../../../../../static';
import ExpenseSummary from './modal/ExpenseSummary';
import SelfReading from './modal/SelfReading';
import MyRequests from './modal/MyRequests';
import MainFooter from './MainFooter';

type Props = {};

const Main = (props: Props) => {
	const { modalStore } = useStore();
	const { documentId } = useParams();
	const navigate = useNavigate();

	const nonTiIncartare = Static.nonTiIncartare;

	let dataCharts: number[];
	let dataLabels: string[];
	let sumDataCharts: number;
	let screenWidth: number;
	let size = 16;
	let padding = 30;
	let myChart: Chart;

	const json = useRouteLoaderData('demoJsonLoader') as Json;

	const onResize = () => {
		screenWidth = window.innerWidth;
		if (myChart) myChart.destroy();
		if (screenWidth <= 816) {
			size = 10;
			padding = 15;
		} else if (screenWidth <= 1100) {
			size = 12;
			padding = 15;
		} else {
			size = 16;
			padding = 30;
		}
		paintChart();
	};

	const paintChart = () => {
		if (myChart) myChart.destroy();
		myChart = new Chart('myChart', {
			plugins: [ChartDataLabels],
			type: 'doughnut',
			data: {
				labels: dataLabels,
				datasets: [
					{
						label: 'Voci Spesa',
						data: dataCharts,
						backgroundColor: [
							'rgba(11,125,2, 0.7)',
							'rgba(4,140,255, 0.7)',
							'rgba(238,140,59, 0.7)',
							'rgba(255, 0, 0, 0.7)',
						],
						borderColor: [
							'rgba(11,125,2, 1)',
							'rgba(4,140,255, 1)',
							'rgba(238,140,59, 1)',
							'rgba(255, 0, 0, 1)',
						],
						borderWidth: 1,
					},
				],
			},
			options: {
				plugins: {
					tooltip: {
						enabled: false,
						callbacks: {
							label: function (context) {
								return (
									context.chart.config.data.labels?.at(context.dataIndex) +
									': ' +
									(
										+context.chart.data.datasets[0].data ??
										[].at(context.dataIndex)
									)
										.toFixed(2)
										.toString()
										.replace('.', ',') +
									' €'
								);
							},
						},
						bodyFont: {
							family: 'Montserrat',
							size: size,
						},
						titleFont: {
							family: 'Montserrat',
							size: size,
						},
						footerFont: {
							family: 'Montserrat',
							size: size,
						},
					},
					legend: {
						display: true,
						position: 'right',
						labels: {
							usePointStyle: true,
							font: {
								family: 'Montserrat',
								size: size,
							},
							padding: padding,
						},
					},
					datalabels: {
						color: '#ffffff',
						font: {
							size: size,
							family: 'Montserrat',
						},
						formatter: (value, ctx) => {
							let datasets = ctx.chart.data.datasets;
							if (datasets.indexOf(ctx.dataset) === datasets.length - 1) {
								let percentage =
									Math.round((value / sumDataCharts) * 100) + '%';
								return percentage;
							} else {
								return '';
							}
						},
					},
				},
			},
		});
	};

	useEffect(() => {
		if (json && json.Grafico) {
			dataCharts = [];
			dataLabels = [];
			sumDataCharts = 0;
			json.Grafico.forEach((value) => {
				dataCharts.push(+value.Valore);
				dataLabels.push('' + value.Etichetta);
				sumDataCharts += +value.Valore;
			});
			paintChart();
		}

		window.addEventListener('resize', onResize);
		return () => {
			window.removeEventListener('resize', onResize);
		};
	}, []);

	if (!json) return <Navigate to='/not-found' />;

	const openModalVideo = () => {
		modalStore.openModal(<Video />);

		agent.Tracking.track(
			Static.openModalVideo + ' ' + Static.fromMainComponent,
			'modal-video'
		);
	};

	const onGoClick = (from: string) => {
		window.open(Static.urlDemoPostaPronta, '_blank');
		agent.Tracking.track(
			from + ' ' + Static.fromMainComponent,
			Static.urlDemoPostaPronta
		);
	};

	const onVirtualAssistantClick = () => {
		window.open(Static.urlDemoVirtualAssistant, '_blank');
		agent.Tracking.track(
			Static.fromMainComponent,
			Static.urlDemoVirtualAssistant
		);
	};

	const openModalSelfReading = () => {
		modalStore.openModal(<SelfReading />);

		agent.Tracking.track(
			Static.openModalSelfReading + ' ' + Static.fromMainComponent,
			'modal-self-reading'
		);
	};

	const openModalMyRequests = () => {
		modalStore.openModal(<MyRequests />);

		agent.Tracking.track(
			Static.openModalMyRequests + ' ' + Static.fromMainComponent,
			'modal-my-requests'
		);
	};

	const goToClientConsumptionDetail = () => {
		window.scroll(0, 0);

		agent.Tracking.track(
			Static.toggleClientConsumptionDetail + ' ' + Static.fromMainComponent,
			'client-consumption-detail'
		);

		navigate('/demo/invoice/luce/document/' + documentId + '/consumption');
	};

	return (
		<div className='mt-4' id='main'>
			<div className='row'>
				<div className='col-md-6 card-columns-full'>
					<div className='me-3 mt-0 ms-3 mb-3'>
						<div className='card p-3 border border-3 border-success p-md-5'>
							<h2>LUCE Plus</h2>
							<h4>
								{json.FatturaEtichetta} n.{' '}
								<span className='green'>{json.Fattura}</span>
							</h4>
							<h4>
								Periodo <span className='green'>{json.PeriodoFattura}</span>
							</h4>
						</div>
					</div>
				</div>

				<div className='col-md-6 card-columns-full'>
					<div className='me-3 mt-0 ms-3 mb-3'>
						<div className='card p-3 border border-3 border-success p-md-5'>
							<h4>
								{json.ImportoEtichetta}{' '}
								<span className='green'>{json.ImportoDaPagare} €</span>
							</h4>
							<h4>Da saldare entro il</h4>
							<h4 className='green'>{json.ScadenzaFattura}</h4>
						</div>
					</div>
				</div>
			</div>

			<div className='row'>
				<div className='col-md-12'>
					<div className='card-columns-full'>
						<div className='me-3 ms-3 '>
							<div className='card p-3 border border-3 border-success p-md-5'>
								<div className='row'>
									<h3>Sintesi degli importi fatturati in Bolletta</h3>
								</div>
								<div className='row'>
									<div className='col-md-6'>
										<div className='me-3 ms-3'>
											<div className='mt-3 mt-md-5 w-100'>
												<canvas id='myChart'></canvas>
											</div>
										</div>
									</div>
									<div className='col-md-6'>
										<div className='me-3 mt-0 ms-3 mb-3'>
											<table className='table table-sm table-borderless mb-0 grey app-semibold'>
												<tbody>
													{json.VociSpesa?.DettagliSpesa.map((item) => (
														<tr
															className={
																item.Etichetta === 'Costo totale bolletta'
																	? 'app-bold'
																	: ''
															}
														>
															<td>{item.Etichetta}</td>
															<td className='app-item-table'>
																{item.Valore} €
															</td>
														</tr>
													))}
													{json.VociSpesa?.Note && (
														<tr>
															<td colSpan={2}>{json.VociSpesa.Note}</td>
														</tr>
													)}
												</tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className='row'>
				<div className='col-md-6'>
					<div className='card-columns-full'>
						<div className='m-3'>
							<div className='card app-card-with-background'>
								<img
									alt=''
									className='card-img app-opacity'
									src='/images/demo/invoice/Box.jpg'
								/>
								<div className='card-img-overlay'>
									<div className='row'>
										<div className='col-3'></div>
										<div className='col-9 text-end'>
											<h3>
												Una soluzione digitale
												<br /> per gestire la tua corrispondenza
											</h3>
											<button
												className='btn mb-0 mb-md-3 mt-1 mt-md-3 app-btn-success text-end'
												onClick={() => onGoClick(nonTiIncartare)}
											>
												SCOPRI DI PIU'
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className='me-3 mt-0 ms-3 mb-3'>
							<div className='card p-3 border border-3 border-success p-md-5'>
								<h3 className='text-center'>
									Hai dubbi o vuoi segnalarci un guasto?
								</h3>
								<div className='text-center'>
									<button
										className='btn app-btn-success mt-1 mt-md-3 app-btn-min-width'
										onClick={openModalMyRequests}
									>
										CONTATTACI
									</button>
									<button
										className='btn app-btn-success mt-1 mt-md-3 app-btn-min-width'
										onClick={onVirtualAssistantClick}
									>
										ASSISTENTE VIRTUALE
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className='col-md-6'>
					<div className='card-columns-full'>
						<div className='m-3'>
							<div className='card p-3 border border-3 border-success p-md-5'>
								<h3 className='text-center'>Verifica i tuoi consumi mensili</h3>
								<div className='text-center'>
									<button
										onClick={goToClientConsumptionDetail}
										className='btn app-btn-success mt-1 mt-md-3 app-btn-min-width'
									>
										CONSUMI MENSILI
									</button>
								</div>
							</div>
						</div>

						<div className='me-3 mt-0 ms-3 mb-3'>
							<div className='card p-3 border border-3 border-success p-md-5'>
								<h3 className='text-center'>Aggiorna i tuoi consumi</h3>
								<div className='text-center'>
									<button
										className='btn app-btn-success mt-1 mt-md-3 app-btn-min-width'
										onClick={openModalSelfReading}
									>
										AUTOLETTURA
									</button>
								</div>
							</div>
						</div>

						<div className='m-3'>
							<div className='card app-card-with-background'>
								<img
									alt=''
									className='card-img app-opacity'
									src='/images/demo/invoice/VideoPreview.gif'
								/>
								<div
									className='card-img-overlay app-pointer'
									onClick={openModalVideo}
								></div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<MainFooter />
		</div>
	);
};

export default Main;
